import { useQuery } from "react-query";
import {
  getGiveawayEntryHistory,
  getGiveaways,
  postEnterGiveaway,
  processError,
} from "../../lib/API";
import Countdown from "react-countdown";
import { useQueryClient } from "../../lib/QueryContext";
import { useState } from "react";
import Alert from "../alerts/Alert";
import ActionButton from "../buttons/ActionButton";
import PlaceholderLoading from "react-placeholder-loading";
import ContentLoader from "react-content-loader";
import PulseDot from "react-pulse-dot";
import "react-pulse-dot/dist/index.css";
import { TableDisplayDate } from "../../utils/TableDisplayDate";


function Giveaways() {
  const { queryClient } = useQueryClient();
  const [page, setPage] = useState(0);
  const {
    isLoading: giveawaysLoading,
    error: giveawaysError,
    data: giveawaysData,
    isFetching: giveawaysFetching,
  } = useQuery(["giveaways"], () =>
    getGiveaways().then((res) => {
      return res.data;
    })
  );

  const {
    isLoading: giveawayEntryHistoryLoading,
    error: giveawayEntryHistoryError,
    data: giveawayEntryHistoryData,
    isFetching: giveawayEntryHistoryFetching,
    isPreviousData: giveawayEntryHistoryPreviousData,
  } = useQuery(
    ["giveawayEntryHistory", page],
    () =>
      getGiveawayEntryHistory(page).then((res) => {
        return res;
      }),
    { keepPreviousData: true }
  );

  const [isEntering, setIsEntering] = useState(false);
  const [isEntered, setIsEntered] = useState(false);
  const [enterError, setEnterError] = useState(null);
  const [enterMessage, setEnterMessage] = useState(null);

  const nextDisabled =
    giveawayEntryHistoryLoading ||
    giveawayEntryHistoryError ||
    (giveawayEntryHistoryData &&
      giveawayEntryHistoryData.page === giveawayEntryHistoryData.totalPages);
  const prevDisabled =
    giveawayEntryHistoryLoading ||
    giveawayEntryHistoryError ||
    (giveawayEntryHistoryData && giveawayEntryHistoryData.page === 1);

    const giveawayActive = giveawaysData && giveawaysData.activeGiveaway;

  return (
    <>
      <div className="card text-center bg-cover bg-[url('/public/assets/img/giveaways-gradient.png')]">
        <div className="body">
          <h1 className="text-5xl text-white">🥳 Giveaways</h1>
          <div className="grid lg:grid-cols-2 grid-cols-1 mt-5">
            <div>
              <div className="m-auto">
                <p className="text-2xl md:text-3xl text-amber-400 font-semibold">
                <span className="mr-1">
                    {giveawaysData && !giveawaysData.activeGiveaway
                      ? "No active giveaway"
                      : "Time remaining: "}
                  </span>
                  {giveawaysData && giveawaysData.activeGiveaway && (
                    <Countdown
                      date={giveawaysData.data.endTime}
                      renderer={(props) => (
                        <span>
                          {props.days > 0 && `${props.days}d`}{" "}
                          {props.hours > 0 && `${props.hours}h`}{" "}
                          {(props.minutes > 0 || props.hours > 0) &&
                            `${props.minutes}m`}{" "}
                          {(props.seconds > 0 || props.minutes > 0) &&
                            `${props.seconds}s`}
                            {props.completed && "ended"}
                        </span>
                      )}
                    >
                    </Countdown>
                  )}
                </p>
              </div>
              <ActionButton
                className="button success w-11/12 mt-6 mb-1 hidden lg:inline"
                loading={isEntering}
                spinnerOnly={true}
                disabled={
                  isEntered ||
                  !giveawaysData ||
                  !giveawaysData.activeGiveaway ||
                  (giveawaysData.activeGiveaway && giveawaysData.data.isEntered)
                }
                onClick={async () => {
                  if (!isEntering) {
                    // clear error and message
                    setIsEntering(true);
                    setEnterError(null);
                    setEnterMessage(null);
                    try {
                      var res = await postEnterGiveaway(giveawaysData.data.id);
                      if (res.message) {
                        setEnterMessage(res.message);
                        setIsEntered(true);
                      } else if (res.error) setEnterError(res.error);

                      queryClient.invalidateQueries(["giveaways"]);
                    } catch (err) {
                      var error = processError(err);
                      setEnterError(error.error);
                    }
                    setIsEntering(false);
                  }
                }}
              >
                Enter Giveaway
              </ActionButton>
              <div className="mb-8">
                {enterError && (
                  <span className="font-medium text-red-500 text-lg">
                    {enterError}
                  </span>
                )}
                {enterMessage && (
                  <span className="font-medium text-sky-300 text-lg">
                    {enterMessage}
                  </span>
                )}
                {giveawaysData &&
                  giveawaysData.activeGiveaway &&
                  giveawaysData.data.isEntered &&
                  !enterMessage && (
                    <span className="font-medium text-sky-300 text-lg">
                      You have already entered this giveaway.
                    </span>
                  )}
              </div>
              <div className="text-white grid grid-cols-2 gap-4 mb-5 lg:mb-0 lg:mt-0">
                <div className="m-auto">
                  <p className="text-2xl md:text-3xl text-green-500 font-semibold">
                    {giveawayActive ? giveawaysData.data.prize : "--"} R$
                  </p>
                  <p className="text-md md:text-md font-medium">
                    <i className="fa-solid fa-sack-dollar"></i> Jackpot
                  </p>
                </div>
                <div className="m-auto">
                  <p className="text-2xl md:text-3xl text-orange-400 font-semibold">
                    {giveawayActive ? giveawaysData.data.entryCount : "--"}
                  </p>
                  <p className="text-md md:text-md font-medium">
                    <i className="fa-solid fa-ticket"></i> Entries
                  </p>
                </div>
              </div>
            </div>

            <div className="text-white m-auto w-10/12 relative h-full">
              <h1 className="pb-2 text-2xl">Recent Winners</h1>
              <div className="grid xmd:pb-2 gap-2 min-h-[7rem]">
                {!giveawaysData && (
                  <PulseDot
                    color="#cccccc"
                    style={{
                      fontSize: "3rem",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                )}
                {giveawaysData &&
                  giveawaysData.lastWinners.map((winner, index) => {
                    return (
                      <div
                        className="card p-1 xs:w-full sm:w-7/12 lg:w-10/12 m-auto"
                        key={index}
                      >
                        <div className="grid grid-cols-5">
                          <img
                            className="!float-left rounded-full w-10 m-auto"
                            src={winner.avatarUrl}
                            alt="rendered image"
                          />
                          <span className="text-black col-span-3 !float-left max-w-[9rem] sm:max-w-[10rem] truncate m-auto">
                            {winner.username}
                          </span>
                          <span className="text-green-500 font-bold !float-right m-auto">
                            {winner.prize} R$
                          </span>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 mt-5">
        <div className="card lg:col-span-2 relative">
          <div className="body ">
            <div className="text-2xl font-semibold">
              <i className="fad fa-list-timeline"></i> Recent Entries
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4  mt-2 min-h-[8rem]">
              {giveawaysData &&
                giveawaysData.lastEntries.map((winner, index) => {
                  return (
                    <div className="card p-1 w-full m-auto" key={index}>
                      <div className="grid grid-cols-5">
                        <img
                          className="text-left rounded-full w-16 m-auto"
                          src={winner.avatarUrl}
                          alt="rendered image"
                        />
                        <div className="text-black col-span-4 text-left ml-1 max-w-[13rem] xmd:max-w-[12.5rem] truncate my-auto mr-auto">
                          {winner.username}
                        </div>
                      </div>
                    </div>
                  );
                })}
              {!giveawaysData && (
                <PulseDot
                  color="#555555"
                  style={{
                    fontSize: "3rem",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-span-2">
          <div className="card">
            <div className="body">
              <div className="text-2xl font-semibold">
                <i className="fad fa-ticket"></i>&nbsp; My Entries
              </div>
              <table className="table-fixed mt-2">
                <thead>
                  <tr>
                    <th>
                      <i className="fad fa-calendar-alt mr-0.5"></i> Date
                    </th>
                    <th>
                      <i className="fad fa-award mr-0.5"></i> Prize
                    </th>
                    <th>
                      <i className="fad fa-question mr-0.5"></i> Won
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {giveawayEntryHistoryData !== undefined &&
                    giveawayEntryHistoryData.history.length >= 1 &&
                    giveawayEntryHistoryData.history.map((entry, index) => (
                      <tr key={index}>
                        <td>{TableDisplayDate(entry.entryTime)}</td>
                        <td>{entry.prize} R$</td>
                        <td>
                          {entry.giveawayActive === false && entry.won && (
                            <i className="fas fa-circle-check text-green-500 text-2xl"></i>
                          )}
                          {entry.giveawayActive === false && !entry.won && (
                            <i className="fas fa-circle-xmark text-red-500 text-2xl"></i>
                          )}
                          {entry.giveawayActive && (
                            <i className="fas fa-hourglass-half text-blue-500 text-2xl"></i>
                          )}
                        </td>
                      </tr>
                    ))}
                  {giveawayEntryHistoryLoading &&
                    giveawayEntryHistoryData === undefined && (
                      // run 5 times
                      // make a row that takes up the entire table
                      <tr
                        style={{
                          height: "100%",
                        }}
                      >
                        <td colSpan={3} rowSpan={5}>
                          <PulseDot
                            color="#555555"
                            style={{
                              fontSize: "3rem",
                            }}
                          />
                        </td>
                      </tr>
                    )}
                  {(giveawayEntryHistoryError ||
                    (giveawayEntryHistoryData &&
                      giveawayEntryHistoryData.page === 1 &&
                      giveawayEntryHistoryData.history.length === 0)) && (
                    // make a row that takes up the entire table
                    <tr
                      style={{
                        height: "100%!important",
                      }}
                    >
                      <td className="h-full" colSpan={3}>
                        <div className="text-center flex text-black text-xl w-full h-full">
                          <span className="m-auto">No records found...</span>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="grid grid-cols-7">
                <div className="inline-block col-span-3">
                  <ActionButton
                    loading={
                      giveawayEntryHistoryPreviousData &&
                      (giveawayEntryHistoryLoading ||
                        giveawayEntryHistoryFetching)
                    }
                    className={`button thin gray mobile-only:w-3/4 mobile-only:text-lg mt-1 pt-1 float-left ${
                      prevDisabled ? "disabled" : ""
                    }`}
                    disabled={prevDisabled}
                    spinnerOnly={true}
                    onClick={() => {
                      if (
                        giveawayEntryHistoryData.page > 1 &&
                        !giveawayEntryHistoryLoading
                      ) {
                        setPage(giveawayEntryHistoryData.page - 1);
                      }
                    }}
                  >
                    Previous
                  </ActionButton>
                </div>
                <div className="inline-block col-span-1 text-center mt-auto mb-auto">
                  {giveawayEntryHistoryData && (
                    <>
                      {giveawayEntryHistoryData.page} /{" "}
                      {giveawayEntryHistoryData.totalPages}
                    </>
                  )}
                </div>
                <div className="inline-block col-span-3">
                  <ActionButton
                    loading={
                      giveawayEntryHistoryPreviousData &&
                      (giveawayEntryHistoryLoading ||
                        giveawayEntryHistoryFetching)
                    }
                    className={`button thin gray mobile-only:w-3/4 mobile-only:text-lg mt-1 pt-1 float-right${
                      nextDisabled ? " disabled" : ""
                    }`}
                    disabled={nextDisabled}
                    spinnerOnly={true}
                    onClick={() => {
                      if (
                        giveawayEntryHistoryData.page <
                          giveawayEntryHistoryData.totalPages &&
                        !giveawayEntryHistoryLoading
                      ) {
                        setPage(giveawayEntryHistoryData.page + 1);
                      }
                    }}
                  >
                    Next
                  </ActionButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Giveaways;
