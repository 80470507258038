import logo from "./logo.svg";
import "./App.css";
import { createContext, useContext, useEffect, useState } from "react";
import { AuthProvider, useAuth } from "./lib/AuthContext";
import { useQuery } from "react-query";
import axios from "axios";
import { getAppData } from "./lib/API";
import { useApp } from "./lib/AppContext";
import { useForm } from "react-hook-form";
import {
  Navigate,
  Route,
  Routes,
  UNSAFE_RouteContext,
  useNavigate,
} from "react-router-dom";
import NavBar from "./components/navigation/Navbar";
import NotFound from "./components/NotFound/NotFound";
import AppLoading from "./components/Loading/AppLoading";
import AppError from "./components/Error/AppError";
import Modal from "./components/modals/Modal";
import Landing from "./components/Pages/Landing";
import Giveaways from "./components/Pages/Giveaways";
import Withdraw from "./components/Pages/Withdraw";
import Wall from "./components/Pages/Wall";
import Referrals from "./components/Pages/Referrals";
import Codes from "./components/Pages/Codes";
import Earn from "./components/Pages/Earn";
import RequireAuth from "./lib/RequireAuth";
import NavAlert from "./components/alerts/NavAlert";
import ActionButton from "./components/buttons/ActionButton";
import Alert from "./components/alerts/Alert";
import Referral from "./components/Pages/Referral";
import RequireNoAuth from "./lib/RequireNoAuth";
import Footer from "./components/Footer/Footer";
import EarningRobux from "./components/Pages/help/EarningRobux";
import GettingStarted from "./components/Pages/help/GettingStarted";
import WithdrawingRobux from "./components/Pages/help/WithdrawingRobux";
import Sponsorships from "./components/Pages/help/Sponsorships";
import MissingRobux from "./components/Pages/help/MissingRobux";
import ContactUs from "./components/Pages/help/ContactUs";
import Terms from "./components/Pages/notes/Terms";
import Privacy from "./components/Pages/notes/Privacy";
import Cookie from "./components/Pages/notes/Cookie";
import Contest from "./components/Pages/Contest";
import Crates from "./components/Pages/Crates";
import Page from "./utils/Page";

function App() {
  const { authState, login, logout, authLoading, user } = useAuth();
  const { appData, appIsLoading, appError } = useApp();

  const {
    register: loginForm,
    handleSubmit: handleLoginSubmit,
    formState: { errors },
  } = useForm();

  const [loginError, setLoginError] = useState(null);
  const [loggingIn, setLoggingIn] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const onLoginSubmit = async (data) => {
    setLoginError(null);
    setLoggingIn(true);
    var res = await login(data.username);
    setLoggingIn(false);

    if (res.success) {
      setIsOpen(false);
      navigate("/earn");
    } else setLoginError(res.error);
  };

  if (authLoading || appIsLoading || appError) {
    return <AppLoading />;
  }

  var appName = appData.applicationInfo.appName;

  const title = (page) => {
    return appName + " | " + page;
  };

  return (
    <>
      <div className="app">
        <NavBar openLogin={() => setIsOpen(true)} />
        {appData.applicationData.globalData.banner.active && 
        <NavAlert
          type={appData.applicationData.globalData.banner.type}
          dangerous={appData.applicationData.globalData.banner.data}
        />}
        <main className="container">
          <Routes>
            <Route element={<RequireNoAuth />}>
              <Route
                path="/"
                exact
                element={
                  <Page title={appName}>
                    <Landing onOpen={() => setIsOpen(true)} />
                  </Page>
                }
              />
              <Route
                path="/r/:id"
                element={<Referral openLogin={() => setIsOpen(true)} />}
              />
            </Route>

            <Route
              element={
                <RequireAuth
                  openLogin={() => {
                    setLoginError(
                      "You need to link your account before you can start earning!"
                    );
                    setIsOpen(true);
                  }}
                />
              }
            >
              <Route
                path="/earn"
                element={
                  <Page title={title("Earn")}>
                    <Earn />
                  </Page>
                }
              />
              <Route
                path="/codes"
                element={
                  <Page title={title("Codes")}>
                    <Codes />
                  </Page>
                }
              />
              <Route
                path="/referrals"
                element={
                  <Page title={title("Referrals")}>
                    <Referrals />
                  </Page>
                }
              />
              <Route
                path="/wall/:id"
                element={
                  <Page title={title("Earning Wall")}>
                    <Wall />
                  </Page>
                }
              />
              <Route
                path="/withdraw"
                element={
                  <Page title={title("Withdraw")}>
                    <Withdraw />
                  </Page>
                }
              />
              <Route
                path="/giveaways"
                element={
                  <Page title={title("Giveaways")}>
                    <Giveaways />
                  </Page>
                }
              />
              <Route
                path="/contest"
                element={
                  <Page title={title("Contest")}>
                    <Contest />
                  </Page>
                }
              />
              <Route
                path="/crates"
                element={
                  <Page title={title("Crates")}>
                    <Crates />
                  </Page>
                }
              />
            </Route>

            <Route
              path="/help/getting-started"
              element={
                <Page title={title("Getting Started")}>
                  <GettingStarted />
                </Page>
              }
            />
            <Route
              path="/help/earning-robux"
              element={
                <Page title={title("Earning Robux")}>
                  <EarningRobux />
                </Page>
              }
            />
            <Route
              path="/help/withdrawing-robux"
              element={
                <Page title={title("Withdrawing Robux")}>
                  <WithdrawingRobux />
                </Page>
              }
            />
            <Route
              path="/help/sponsorships"
              element={
                <Page title={title("Sponsorships")}>
                  <Sponsorships />
                </Page>
              }
            />
            <Route
              path="/help/missing-robux"
              element={
                <Page title={title("Missing Robux")}>
                  <MissingRobux />
                </Page>
              }
            />
            <Route
              path="/help/contact-us"
              element={
                <Page title={title("Contact Us")}>
                  <ContactUs />
                </Page>
              }
            />

            <Route
              path="/notes/terms-of-service"
              element={
                <Page title={title("Terms Of Service")}>
                  <Terms />
                </Page>
              }
            />
            <Route
              path="/notes/privacy-policy"
              element={
                <Page title={title("Privacy Policy")}>
                  <Privacy />
                </Page>
              }
            />
            <Route
              path="/notes/cookie-policy"
              element={
                <Page title={title("Cookie Policy")}>
                  <Cookie />
                </Page>
              }
            />

            <Route
              path="*"
              element={
                <Page title={title("Page Not Found")}>
                  <NotFound />
                </Page>
              }
            />
          </Routes>
        </main>
        <Footer />
      </div>
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title="Link Your ROBLOX Account"
        additionalStyles={{
          minWidth: "350px",
          minHeight: "200px",
        }}
      >
        <form onSubmit={handleLoginSubmit(onLoginSubmit)}>
          {Object.values(errors).map((e, idx) => {
            return (
              <Alert type="danger" key={idx}>
                {e.message}
              </Alert>
            );
          })}
          {loginError && <Alert type="danger">{loginError}</Alert>}
          <input
            type="text"
            className="form"
            placeholder="Username"
            {...loginForm("username", {
              onChange: (e) => setLoginError(null),
              required: {
                value: true,
                message: "Username is required",
              },
              max: {
                value: 20,
                message: "Username must be no more than 20 characters",
              },
              min: {
                value: 3,
                message: "Username must be at least 3 characters",
              },
              minLength: {
                value: 3,
                message: "Username must be at least 3 characters",
              },
              maxLength: {
                value: 20,
                message: "Username must be no more than 20 characters",
              },
            })}
          />
          <ActionButton
            className="button primary text-md thin w-full mt-1"
            icon="fa-link"
            loading={loggingIn}
            type="submit"
          >
            Link Account
          </ActionButton>
        </form>
        <div className="text-xs mt-1">
          Linking your ROBLOX account only requires your username. We will{" "}
          <b>
            <u>
              <i>
                <em>
                  <strong>never</strong>
                </em>
              </i>
            </u>
          </b>{" "}
          ask for your password.
        </div>
      </Modal>
    </>
  );
}

export default App;
