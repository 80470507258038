import { useApp } from "../../lib/AppContext";
import { nFormatter } from "../../utils/nFormatter";
import Alert from "../alerts/Alert";
import { useQuery } from "react-query";
import { getCodesHistory, postSubmitCode } from "../../lib/API";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../lib/AuthContext";
import { useQueryClient } from "../../lib/QueryContext";
import ActionButton from "../buttons/ActionButton";
import PlaceholderLoading from "react-placeholder-loading";
import PulseDot from "react-pulse-dot";
import { TableDisplayDate } from "../../utils/TableDisplayDate";

function Codes() {
  const { appData } = useApp();
  const { refreshUser } = useAuth();
  const { queryClient } = useQueryClient();
  const [page, setPage] = useState(0);

  const {
    register: codeForm,
    handleSubmit: handleCodeSubmit,
    formState: { errors },
  } = useForm();
  const [codeSubmitError, setCodeSubmitError] = useState(null);
  const [submittingCode, setSubmittingCode] = useState(false);
  const [codeSubmitMessage, setCodeSubmitMessage] = useState(null);

  const {
    isLoading: codeHistoryLoading,
    error: codeHistoryError,
    data: codeHistoryData,
    isFetching: codeHistoryFetching,
    isPreviousData: codeHistoryPreviousData,
  } = useQuery(
    ["codesHistory", page],
    () =>
      getCodesHistory(page).then((res) => {
        return res;
      }),
    { keepPreviousData: true }
  );

  const clearCodeErrors = () => {
    setCodeSubmitError(null);
    setCodeSubmitMessage(null);
  };

  const onCodeSubmit = async (data) => {
    clearCodeErrors();
    setSubmittingCode(true);
    var res = await postSubmitCode(data.code);
    await queryClient.invalidateQueries(["codesHistory"]);
    setSubmittingCode(false);
    await refreshUser();

    if (res.success) {
      if (res.message) setCodeSubmitMessage(res.message);

      if (res.error) setCodeSubmitError(res.error);
    } else setCodeSubmitError(res.error);
  };

  const nextDisabled =
    codeHistoryLoading ||
    codeHistoryError ||
    (codeHistoryData && codeHistoryData.page === codeHistoryData.totalPages);
  const prevDisabled =
    codeHistoryLoading ||
    codeHistoryError ||
    (codeHistoryData && codeHistoryData.page === 1);

  return (
    <>
      <div className="card">
        <div className="body">
          <div className="text-2xl font-semibold">
            <i className="fad fa-gift"></i>&nbsp; Promotional Codes
          </div>
          <div>Redeem promotional codes found online for Robux!</div>
          <br />
          {codeSubmitMessage && <Alert type="info">{codeSubmitMessage}</Alert>}
          {codeSubmitError && <Alert type="danger">{codeSubmitError}</Alert>}
          {Object.values(errors).map((e, idx) => {
            return (
              <Alert type="danger" key={idx}>
                {e.message}
              </Alert>
            );
          })}
          <form onSubmit={handleCodeSubmit(onCodeSubmit)}>
            <div className="flex">
              <input
                type="text"
                className="form"
                placeholder="Enter your promotional code..."
                {...codeForm("code", {
                  onChange: (e) => clearCodeErrors(),
                  required: {
                    value: true,
                    message: "Please enter a code.",
                  },
                })}
              />
            </div>
            <div className="flex">
              <ActionButton
                className={`button thin primary w-full mt-1${
                  submittingCode ? " disabled" : ""
                }`}
                type="submit"
                loading={submittingCode}
              >
                Redeem
              </ActionButton>
            </div>
          </form>
          <div className="grid md:grid-cols-3 mobile-only:grid-cols-1 mobile-only:grid-rows-3 gap-2 text-center mt-6">
            <div className="block">
              <div className="text-black text-2xl">
                {nFormatter(
                  appData.applicationData.promotionalStatistics.totalRedeemed
                )}{" "}
                Points
              </div>
              <div className="text-xl font-semibold">Redeemed</div>
            </div>
            <div className="block">
              <div className="text-black text-2xl">
                {nFormatter(
                  appData.applicationData.promotionalStatistics.totalActive
                )}
              </div>
              <div className="text-xl font-semibold">Active Promocodes</div>
            </div>
            <div className="block">
              <div className="text-black text-2xl">
                {nFormatter(
                  appData.applicationData.promotionalStatistics.totalRedemptions
                )}
              </div>
              <div className="text-xl font-semibold">Redemptions</div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5"></div>
      <div className="card">
        <div className="body">
          <div className="text-2xl font-semibold">
            <i className="fad fa-list-timeline"></i>&nbsp; Redemption History
          </div>
          <div>Your past promotional code redemptions.</div>
          <table className="table-fixed mt-2">
            <thead>
              <tr>
                <th>
                  <i className="fad fa-calendar-alt mr-0.5"></i> Date
                </th>
                <th>
                  <i className="fad fa-gift mr-0.5"></i> Code
                </th>
                <th>
                  <i className="fad fa-sack-dollar mr-0.5"></i> Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {codeHistoryData !== undefined &&
                codeHistoryData.history.length >= 1 &&
                codeHistoryData.history.map((code, index) => (
                  <tr key={index}>
                    <td>{TableDisplayDate(code.redemptionTime)}</td>
                    <td className="font-bold uppercase">{code.code}</td>
                    <td>{code.value}</td>
                  </tr>
                ))}
              {codeHistoryLoading && codeHistoryData === undefined && (
                <tr>
                  <td colSpan={3} rowSpan={5}>
                    <PulseDot
                      color="#555555"
                      style={{
                        fontSize: "3rem",
                      }}
                    />
                  </td>
                </tr>
              )}
              {(codeHistoryError ||
                (codeHistoryData &&
                  codeHistoryData.page === 1 &&
                  codeHistoryData.history.length === 0)) && (
                <tr>
                  <td className="w-full" colSpan={3} key="1">
                    <div className="text-center text-black text-xl w-full">
                      No records found...
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="grid grid-cols-7">
            <div className="inline-block col-span-3">
              <ActionButton
                loading={
                  codeHistoryPreviousData &&
                  (codeHistoryLoading || codeHistoryFetching)
                }
                className={`button thin gray mobile-only:w-3/4 mobile-only:text-lg mt-1 pt-1 float-left ${
                  prevDisabled ? "disabled" : ""
                }`}
                disabled={prevDisabled}
                spinnerOnly={true}
                onClick={() => {
                  if (codeHistoryData.page > 1 && !codeHistoryLoading) {
                    setPage(codeHistoryData.page - 1);
                  }
                }}
              >
                Previous
              </ActionButton>
            </div>
            <div className="inline-block col-span-1 text-center mt-auto mb-auto">
              {codeHistoryData && (
                <>
                  {codeHistoryData.page} / {codeHistoryData.totalPages}
                </>
              )}
            </div>
            <div className="inline-block col-span-3">
              <ActionButton
                loading={
                  codeHistoryPreviousData &&
                  (codeHistoryLoading || codeHistoryFetching)
                }
                className={`button thin gray mobile-only:w-3/4 mobile-only:text-lg mt-1 pt-1 float-right${
                  nextDisabled ? " disabled" : ""
                }`}
                disabled={nextDisabled}
                spinnerOnly={true}
                onClick={() => {
                  if (
                    codeHistoryData.page < codeHistoryData.totalPages &&
                    !codeHistoryLoading
                  ) {
                    setPage(codeHistoryData.page + 1);
                  }
                }}
              >
                Next
              </ActionButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Codes;
