import { NavLink } from "react-router-dom";

function NotesNav()
{
    return (
        <div className="text-center">
        <NavLink to="/notes/terms-of-service">
          <div className="card p-5 !rounded-b-none help-nav">Terms of Service</div>
        </NavLink>
        <NavLink to="/notes/privacy-policy">
          <div className="card p-5 !rounded-none !border-t-0 help-nav">
            Privacy Policy
          </div>
        </NavLink>
        <NavLink to="/notes/cookie-policy">
          <div className="card p-5 !rounded-t-none !border-t-0 help-nav">
            Cookie Policy
          </div>
        </NavLink>
      </div>
    )
}

export default NotesNav;