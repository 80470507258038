import { Link } from "react-router-dom";

function NotFound() {
    return (
		<div className="card">
			<div className="text-center pt-5">
				<i className="fa-duotone fa-magnifying-glass text-6xl"></i>
			</div>
			<div className="text-center">
				<span className="text-3xl font-semibold text-black">Not Found</span>
			</div>
			<div className="text-center pt-1">
				<span className="text-black">
					The resource you are looking for does not exist.
				</span>
			</div>
			<div className="text-center text-md p-2 pb-5">
				<Link to="/" className="button danger w-2/6">
					Go Back
				</Link>
			</div>
		</div>
    );
}

export default NotFound;