import { useApp } from "../../lib/AppContext";
import { useAuth } from "../../lib/AuthContext";
import { nFormatter } from "../../utils/nFormatter";

function Referrals()
{
    const { appData } = useApp();
    const { user } = useAuth();
    return (
        <>
          <div className="card">
            <div className="body">
              <div className="text-2xl font-semibold">
                <i className="fad fa-users-medical"></i>&nbsp; User Referrals
              </div>
              <div>Refer users to our website and get a cut of their earnings forever!</div>
              <div className="flex mt-2 border-b-2">
                  <div className="text-black text-xl">
                  It's that easy. Refer users, get ROBUX.
                  </div>
              </div>
              <div className="mt-2">
              <div className="text-md">
              <i className="fa-solid fa-check text-green-500"></i> <b>{appData.applicationData.referralStatistics.referralPercentage}%</b> of their earnings forever!
              </div>
              <div className="text-md">
              <i className="fa-solid fa-check text-green-500"></i> Referral earnings are credited <b>immediately</b>.
              </div>
              <div className="text-md">
              <i className="fa-solid fa-check text-green-500"></i> The user <b>must</b> be new to the website.
              </div>
              </div>
              <div className="mt-2">
              <textarea
                  type="text"
                  className="form"
                  rows="2"
                  readOnly="readOnly"
                  id="copyReferralLink"
                  value={`${appData.applicationData.referralStatistics.invitationText} ${window.location.origin.toString()}/r/${user.referralStatistics.referralCode}`}
                  onClick={(e) => {
                    e.target.select();
                    document.execCommand("copy");
                    document.getSelection().removeAllRanges();
                  }}
                  ></textarea>
                  
              <div className="text-xs font-semibold ml-1" onClick={() => {
                document.getElementById("copyReferralLink").click();
              }}>Click to copy</div>
                  </div>
              <div className="grid mobile-only:grid-cols-1 mobile-only:grid-rows-2 grid-cols-2 grid-rows-1 gap-4 text-center mt-4">
                <div className="grid justify-items-center">
                  <div className="card w-11/12 bg-gradient-to-r from-green-400 to-green-500 text-white">
                    <div className="body">
                      <div className="text-2xl font-semibold">
                        Your Stats
                      </div>
                      <div className="grid grid-cols-2 grid-rows-1 gap-4 text-center mt-6">
                        <div className="block">
                          <div className="text-2xl">{nFormatter(user.referralStatistics.totalReferrals)}</div>
                          <div className="text-xl font-semibold"><i className="fa-solid fa-user mr-1"></i> Referrals</div>
                        </div>
                        <div className="block">
                          <div className="text-2xl">{nFormatter(user.referralStatistics.totalEarned)}</div>
                          <div className="text-xl font-semibold"><i className="fa-solid fa-sack-dollar mr-1"></i> Earned</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid justify-items-center">
                  <div className="card w-11/12 bg-gradient-to-r from-red-400 to-red-500 text-white">
                    <div className="body">
                      <div className="text-white text-2xl font-semibold">
                        Global Stats
                      </div>
                      <div className="grid grid-cols-2 grid-rows-1 gap-4 text-center mt-6">
                        <div className="block">
                          <div className="text-2xl">{nFormatter(appData.applicationData.referralStatistics.totalReferrals)}</div>
                          <div className="text-xl font-semibold"><i className="fa-solid fa-user mr-1"></i> Referrals</div>
                        </div>
                        <div className="block">
                          <div className="text-2xl">{nFormatter(appData.applicationData.referralStatistics.totalEarned)}</div>
                          <div className="text-xl font-semibold"><i className="fa-solid fa-sack-dollar mr-1"></i> Earned</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
}

export default Referrals;