import Cookies from "js-cookie";
import axios from "axios";

// get hostname
const hostname = window.location.hostname;
//https://api.quickrbx.com/api/v1
//https://api.legitrbx.com/api/v1
const BaseUrl = 'https://api.bloxcenter.com/api/v1';

// not used currently
export const getHeaders = () => {
  return {
    kyloAuthorization: Cookies.get("kyloAuthorization"),
    "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
  };
};

export const postSubmitCode = async (code) => {
  const response = await axios.post(
    BaseUrl + "/user/codes/redeem",
    {
      code: code,
    },
    {
      headers: {
        "X-AUTH-TOKEN": Cookies.get("kyloAuthorization"),
      },
    }
  );
  return response.data;
};

export const getCodesHistory = async (page = 0) => {
  const response = await axios.get(
    `${BaseUrl}/user/codes/history?page=${page}`,
    {
      headers: {
        "X-AUTH-TOKEN": Cookies.get("kyloAuthorization"),
      },
    }
  );
  return response.data;
};

export const getCratesHistory = async (page = 0) => {
  const response = await axios.get(`${BaseUrl}/crates/history?page=${page}`, {
    headers: {
      "X-AUTH-TOKEN": Cookies.get("kyloAuthorization"),
    },
  });
  return response.data;
};

export const getGiveawayEntryHistory = async (page = 0) => {
  const response = await axios.get(
    `${BaseUrl}/giveaways/history?page=${page}`,
    {
      headers: {
        "X-AUTH-TOKEN": Cookies.get("kyloAuthorization"),
      },
    }
  );
  return response.data;
};

export const getContestEntryHistory = async (page = 0) => {
  const response = await axios.get(`${BaseUrl}/contest/history?page=${page}`, {
    headers: {
      "X-AUTH-TOKEN": Cookies.get("kyloAuthorization"),
    },
  });
  return response.data;
};

export const getWithdrawalHistory = async (page = 0) => {
  const response = await axios.get(`${BaseUrl}/withdraw/history?page=${page}`, {
    headers: {
      "X-AUTH-TOKEN": Cookies.get("kyloAuthorization"),
    },
  });
  return response.data;
};

export const getUser = async () => {
  let authResponse = await axios.get(BaseUrl + "/user", {
    headers: {
      "X-AUTH-TOKEN": Cookies.get("kyloAuthorization"),
    },
  });
  return authResponse.data;
};

export const postLogin = async (username) => {
  let authResponse = await axios.post(BaseUrl + "/user/login", {
    username: username,
    ...(Cookies.get("kyloReferral") !== undefined && {
      referral: Cookies.get("kyloReferral"),
    }),
  });
  if (Cookies.get("kyloReferral") !== undefined) Cookies.remove("kyloReferral");

  return authResponse.data;
};

export const postViewGiftcard = async (withdrawId) => {
    let authResponse = await axios.post(
        BaseUrl + "/withdraw/history/giftcards/view",
        {
            withdrawId: withdrawId,
        },
        {
            headers: {
                "X-AUTH-TOKEN": Cookies.get("kyloAuthorization"),
            },
        }
    );
    return authResponse.data;
}

export const postPurchaseCrate = async (crateId) => {
  let authResponse = await axios.post(
    BaseUrl + "/crates/purchase",
    {
      crateId: crateId,
    },
    {
      headers: {
        "X-AUTH-TOKEN": Cookies.get("kyloAuthorization"),
      },
    }
  );
  return authResponse.data;
};

export const postEnterGiveaway = async (giveawayId) => {
  let authResponse = await axios.post(
    BaseUrl + `/giveaways/${giveawayId}/enter`,
    null,
    {
      headers: {
        "X-AUTH-TOKEN": Cookies.get("kyloAuthorization"),
      },
    }
  );
  return authResponse.data;
};

export const postEnterContest = async (contestId, submission) => {
  let authResponse = await axios.post(
    BaseUrl + `/contest/${contestId}/enter`,
    {
      submission: submission,
    },
    {
      headers: {
        "X-AUTH-TOKEN": Cookies.get("kyloAuthorization"),
      },
    }
  );
  return authResponse.data;
};

export const postOpenCrate = async (inventoryItemId) => {
  let authResponse = await axios.post(
    BaseUrl + "/crates/open",
    {
      inventoryItemId: inventoryItemId,
    },
    {
      headers: {
        "X-AUTH-TOKEN": Cookies.get("kyloAuthorization"),
      },
    }
  );
  return authResponse.data;
};

export const postRedeemGiftcard = async (giftcardId) => {
  let authResponse = await axios.post(
    BaseUrl + "/giftcards/redeem",
    {
      giftcardId: giftcardId,
    },
    {
      headers: {
        "X-AUTH-TOKEN": Cookies.get("kyloAuthorization"),
      },
    }
  );
  return authResponse.data;
};

export const postVerificationRequest = async (requestId = null) => {
  let authResponse = await axios.post(
    BaseUrl +
      "/user/verify" +
      (requestId !== null ? "?requestId=" + requestId : ""),
    {},
    {
      headers: {
        "X-AUTH-TOKEN": Cookies.get("kyloAuthorization"),
      },
    }
  );
  return authResponse.data;
};

export const postGroupWithdraw = async (stockerId, amount) => {
  let authResponse = await axios.post(
    BaseUrl + "/withdraw/group",
    {
      stockerId: stockerId,
      amount: amount,
    },
    {
      headers: {
        "X-AUTH-TOKEN": Cookies.get("kyloAuthorization"),
      },
    }
  );
  return authResponse.data;
};

export const postVipWithdraw = async (amount) => {
  let authResponse = await axios.post(
    BaseUrl + "/withdraw/vip",
    {
      amount: amount,
    },
    {
      headers: {
        "X-AUTH-TOKEN": Cookies.get("kyloAuthorization"),
      },
    }
  );
  return authResponse.data;
};

export const getAppData = async () => {
  return await axios.get(BaseUrl + "/app");
};

export const getPlaceLink = async () => {
  let authResponse = await axios.get(BaseUrl + "/withdraw/placeLink", {
    headers: {
      "X-AUTH-TOKEN": Cookies.get("kyloAuthorization"),
    },
  });
  return authResponse.data;
};

export const getGiveaways = async () => {
  return await axios.get(BaseUrl + "/giveaways/active", {
    headers: {
      "X-AUTH-TOKEN": Cookies.get("kyloAuthorization"),
    },
  });
};

export const getGiftcardTypes = async () => {
  return await axios.get(BaseUrl + "/giftcards/types", {
    headers: {
      "X-AUTH-TOKEN": Cookies.get("kyloAuthorization"),
    },
  });
};

export const getWithdrawInfo = async () => {
  return await axios.get(BaseUrl + "/withdraw/info", {
    headers: {
      "X-AUTH-TOKEN": Cookies.get("kyloAuthorization"),
    },
  });
};

export const getCrateTypes = async () => {
  return await axios.get(BaseUrl + "/crates/types", {
    headers: {
      "X-AUTH-TOKEN": Cookies.get("kyloAuthorization"),
    },
  });
};

export const getCrateInventory = async () => {
  return await axios.get(BaseUrl + "/crates/inventory", {
    headers: {
      "X-AUTH-TOKEN": Cookies.get("kyloAuthorization"),
    },
  });
};

export const getContest = async () => {
  return await axios.get(BaseUrl + "/contest/active", {
    headers: {
      "X-AUTH-TOKEN": Cookies.get("kyloAuthorization"),
    },
  });
};

export const processError = (error) => {
  switch (error.code) {
    case "ERR_NETWORK":
      return {
        success: false,
        error: "Network error",
      };
    case "ERR_BAD_REQUEST":
      return {
        success: false,
        error: "Server error",
      };
    default:
      return {
        success: false,
        error: "Unknown error",
      };
  }
};

export const checkWebErrors = (data) => {
  // returns true if there is a server error
  switch (data.error) {
    case "UNPROCESSABLE_ENTITY":
      return {
        webError: true,
        error: "The input could not be processed",
      };
    case "UNAUTHORIZED":
      return {
        webError: true,
        error: "You are not authorized to access this resource",
      };
    case "FORBIDDEN":
      return {
        webError: true,
        error: "You are not authorized to access this resource",
      };
    case "NOT_FOUND":
      return {
        webError: true,
        error: "The resource you are looking for could not be found",
      };
    case "INTERNAL_SERVER_ERROR":
      return {
        webError: true,
        error: "An internal server error has occurred",
      };
    default:
      return {
        webError: false,
      };
  }
};
