import { useEffect, useState } from "react";
import PulseDot from "react-pulse-dot";
import { useQuery } from "react-query";
import {
  getCrateInventory,
  getCratesHistory,
  getCrateTypes,
  postOpenCrate,
  postPurchaseCrate,
  postVerificationRequest,
  processError,
} from "../../lib/API";
import { useAuth } from "../../lib/AuthContext";
import { useQueryClient } from "../../lib/QueryContext";
import { TableDisplayDate } from "../../utils/TableDisplayDate";
import { useInterval } from "../../utils/useInterval";
import ActionButton from "../buttons/ActionButton";
import Modal from "../modals/Modal";
import { Wheel } from "react-custom-roulette";

// generate wheel data with alternating colors
const generateWheelData = (odds, typesData) => {
  let data = [];
  for (let i = 0; i < odds.length; i++) {
    // alternate colors between 3 colors, #1565c0 #1e88e5 and #2e7d32
    let color = i % 3 === 0 ? "#1565c0" : i % 3 === 1 ? "#1e88e5" : "#1f70b8";
    const prizeType = odds[i].prizeType;
    const prizeValue = odds[i].prize;
    // get crate type name from crate type id which comes from prizeValue if prizeType is 1
    const crateTypeName =
      prizeType === 1
        ? typesData.data.find((type) => type.id === prizeValue).name
        : "";
    data.push({
      option: prizeType === 0 ? prizeValue + " R$" : crateTypeName,
      style: { textColor: 'white', backgroundColor: color },
    });
  }
  return data;
};

function Crates() {
  const {
    isLoading: typesLoading,
    error: typesError,
    data: typesData,
    isFetching: typesFetching,
  } = useQuery(["crateTypes"], () =>
    getCrateTypes().then((res) => {
      return res.data;
    }),
    {
      keepPreviousData: true,
    }
  );

  const [page, setPage] = useState(0);
  const [wheelData, setWheelData] = useState([]);

  const {
    isLoading: crateHistoryLoading,
    error: crateHistoryError,
    data: crateHistoryData,
    isFetching: crateHistoryFetching,
    isPreviousData: crateHistoryPreviousData,
  } = useQuery(
    ["cratesHistory", page],
    () =>
      getCratesHistory(page).then((res) => {
        return res;
      }),
    { keepPreviousData: true }
  );

  const {
    isLoading: inventoryLoading,
    error: inventoryError,
    data: inventoryData,
    isFetching: inventoryFetching,
  } = useQuery([typesData && "inventory"], () =>
    getCrateInventory().then((res) => {
      res.data.data.forEach((crate) => {
        crate.crate = typesData.data.find((type) => type.id === crate.type);
      });
      return res.data;
    })
  );

  const { refreshUser } = useAuth();

  const { queryClient } = useQueryClient();

  const [summaryOpen, setSummaryOpen] = useState(false);
  const [summaryMode, setSummaryMode] = useState("");

  const [openId, setOpenId] = useState(null);
  const [oddId, setOddId] = useState(null);

  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [crateResultOpen, setCrateResultOpen] = useState(false);
  const [crateResultMessage, setCrateResultMessage] = useState("");
  const [crateResultNext, setCrateResultNext] = useState(false);
  const [purchaseOpen, setPurchaseOpen] = useState(false);

  const [wheelModalOpen, setWheelModalOpen] = useState(false);

  const [verifySessionOpen, setVerifySessionOpen] = useState(false);
  const [verifySessionData, setVerifySessionData] = useState(null);
  const [verifyWords, setVerifyWords] = useState("");

  const [pendingAction, setPendingAction] = useState(false);
  const [targetCrate, setTargetCrate] = useState(null);

  const [wheelCrateId, setWheelCrateId] = useState(null);

  const nextDisabled =
    crateHistoryLoading ||
    crateHistoryError ||
    (crateHistoryData && crateHistoryData.page === crateHistoryData.totalPages);
  const prevDisabled =
    crateHistoryLoading ||
    crateHistoryError ||
    (crateHistoryData && crateHistoryData.page === 1);

  useInterval(async () => {
    await updateVerification();
  }, 1000);

  const updateVerification = async () => {
    if (verifySessionOpen && verifySessionData !== null) {
      var verifyReqId = verifySessionData.verificationRequestId;
      try {
        var verifyReq = await postVerificationRequest(verifyReqId);
        if (verifyReq.success) {
          // see if isVerified is true
          if (verifyReq.isVerified) {
            setSummaryOpen(true);
            setVerifySessionOpen(false);
            setVerifySessionData(null);
            // if mode is purchase
            if (summaryMode === "purchase") {
              purchaseCrate();
            }
            if (summaryMode === "open") {
              openCrate(openId, targetCrate.id);
            }
          }
        } else {
          switch (verifyReq.error) {
            case "ERR_REQ_EXPIRED":
              setErrorMessage(
                "Your verification request has expired. Please try again."
              );
              break;
            case "ERR_REQ_NOT_FOUND":
              setErrorMessage(
                "Your verification request could not be found. Please try again."
              );
              break;
            case "ERR_SESSION_ALREADY_VERIFIED":
              setErrorMessage(
                "Your session has already been verified. Please try again."
              );
              break;
            default:
              setErrorMessage(
                "An unknown error has occurred. Please try again."
              );
              break;
          }
          setErrorOpen(true);
          setVerifySessionOpen(false);
        }
      } catch (err) {
        var error = processError(err);
        setErrorMessage(error.error);
        setErrorOpen(true);
        setVerifySessionOpen(false);
        setVerifySessionData(null);
      }
    }
  };

  const openCrateSummary = (crateId, mode) => {
    // get crate name from id
    const crateName = typesData.data.find((crate) => crate.id === crateId).name;
    setTargetCrate(typesData.data.find((crate) => crate.id === crateId));
    setSummaryOpen(true);
    setSummaryMode(mode);
  };

  const openVerifySession = async () => {
    setVerifySessionOpen(true);
    setSummaryOpen(false);

    try {
      // make inital req to start verification request
      var initV = await postVerificationRequest();
      if (initV.success) {
        setVerifyWords(initV.verifyWords);
        setVerifySessionData(initV);
      }
    } catch (err) {
      var error = processError(err);
      setErrorMessage(error.error);
      setErrorOpen(true);
      setVerifySessionOpen(false);
    }
  };

  const openCrate = async (itemInventoryId, crateId) => {
    setPendingAction(true);
    try {
      var crateToUse = typesData.data.find((crate) => crate.id === crateId);
      var response = await postOpenCrate(itemInventoryId);
      if (response.success) {
        setCrateResultNext(false);
        var newWheelData = generateWheelData(crateToUse.odds, typesData);
        setWheelData(newWheelData);
        setOddId(response.oddId);
        if (response.prizeType === 0) {
          setCrateResultMessage(
            "You got " + response.prizeValue + " R$ from this crate!"
          );
        } else if (response.prizeType === 1) {
          // crate win, lookup crate based on prizeValue
          const crate = typesData.data.find(
            (crate) => crate.id === response.prizeValue
          );
          setCrateResultMessage(
            "You got (1) " + crate.name + " from this crate!"
          );
          // if its a crate win, set the openId to the new one
          setTargetCrate(crate);
          setOpenId(response.inventoryItemId);
          setCrateResultNext(true);
        }
        setWheelModalOpen(true);
        setPurchaseOpen(false);
        setSummaryOpen(false);
        setCrateResultOpen(false);
      } else {
        switch (response.error) {
          case "ERR_CRATE_UNAVAILABLE":
            setErrorMessage("This crate is currently unavailable.");
            setErrorOpen(true);
            setCrateResultOpen(false);
            break;
          case "ERR_INV_ITEM_NOT_FOUND":
            setErrorMessage("This crate could not be found.");
            setErrorOpen(true);
            setCrateResultOpen(false);
            break;
          case "ERR_SESSION_UNVERIFIED":
            setErrorMessage(
              "You must verify your account before you can purchase crates."
            );
            openVerifySession();
            break;
          default:
            setErrorMessage("An unknown error has occurred. Please try again.");
            setErrorOpen(true);
            setCrateResultOpen(false);
            break;
        }
      }
    } catch (err) {
      var error = processError(err);
      setErrorMessage(error.error);
      setErrorOpen(true);
      setPendingAction(false);
    }
    setPendingAction(false);
  };

  const purchaseCrate = async () => {
    setPendingAction(true);
    try {
      var response = await postPurchaseCrate(targetCrate.id);
      if (response.success) {
        queryClient.invalidateQueries(["inventory"]);
        setOpenId(response.inventoryItemId);
        await refreshUser();
        setPurchaseOpen(true);
        setSummaryOpen(false);
      } else {
        switch (response.error) {
          case "ERR_CRATE_UNAVAILABLE":
            setErrorMessage("The crate you tried to purchase is unavailable.");
            setErrorOpen(true);
            setSummaryOpen(false);
            break;
          case "ERR_SESSION_UNVERIFIED":
            setErrorMessage(
              "You must verify your account before you can purchase crates."
            );
            openVerifySession();
            break;
          case "ERR_NOT_ENOUGH_BALANCE":
            setErrorMessage(
              "You do not have enough balance to purchase this crate."
            );
            setErrorOpen(true);
            setSummaryOpen(false);
            break;
          default:
            setErrorMessage("An unknown error occurred.");
            setErrorOpen(true);
            setSummaryOpen(false);
            break;
        }
      }
    } catch (err) {
      var error = processError(err);
      setErrorMessage(error.error);
      setErrorOpen(true);
      setSummaryOpen(false);
    }
    setPendingAction(false);
  };

  const closeVerifySession = () => {
    setVerifySessionOpen(false);
    setVerifySessionData(null);
  };

  const closePurchase = () => {
    setPurchaseOpen(false);
  };

  const closeCrateSummary = () => {
    if (!pendingAction) {
      setSummaryOpen(false);
    }
  };

  const closeError = () => {
    setErrorOpen(false);
    setErrorMessage("Unknown error");
  };

  const closeCrateResult = () => {
    setCrateResultOpen(false);
    setCrateResultMessage("");
    setCrateResultNext(false);
  };

  return (
    <>
      <Modal
        open={wheelModalOpen}
        transitiveClose={crateResultOpen}
        transitiveOpen={true}
        additionalStyles={{
          minWidth: "350px",
          minHeight: "220px",
        }}
        exclusiveModal={true}
      >
        <div className="flex justify-center align-middle px-6">
          <Wheel
            mustStartSpinning={wheelModalOpen}
            prizeNumber={oddId}
            data={wheelData}
            textDistance={70}
            perpendicularText={true}
            onStopSpinning={async () => {
              queryClient.invalidateQueries(["inventory"]);
              await refreshUser();
              setCrateResultOpen(true);
              setWheelModalOpen(false);
            }}
          />
        </div>
      </Modal>
      <Modal
        open={crateResultOpen}
        onClose={closeCrateResult}
        transitiveClose={wheelModalOpen}
        transitiveOpen={true}
        title="Crate Result"
        additionalStyles={{
          minWidth: "350px",
          minHeight: "150px",
        }}
      >
        <p className="text-lg text-green-500">{crateResultMessage}</p>
        <div className="w-full flex justify-end mt-2">
          <button className="button danger thin" onClick={closeCrateResult}>
            Close
          </button>
          {crateResultNext && (
            <ActionButton
              className="button primary thin ml-2"
              loading={pendingAction}
              onClick={() => {
                openCrate(openId, targetCrate.id);
              }}
            >
              Open Now
            </ActionButton>
          )}
        </div>
      </Modal>
      <Modal
        open={purchaseOpen}
        onClose={closePurchase}
        transitiveOpen={true}
        transitiveClose={wheelModalOpen}
        title="Crate Purchased"
        additionalStyles={{
          minWidth: "350px",
          minHeight: "150px",
        }}
      >
        <p>
          You have successfully purchased an {targetCrate && targetCrate.name}{" "}
          for {targetCrate && targetCrate.price} R$.
        </p>
        <div className="w-full flex justify-end mt-2">
          <button className="button danger thin" onClick={closePurchase}>
            Close
          </button>
          <ActionButton
            className="button primary thin ml-2"
            onClick={() => {
              openCrate(openId, targetCrate.id);
            }}
          >
            Open Now
          </ActionButton>
        </div>
      </Modal>
      <Modal
        open={verifySessionOpen}
        onClose={closeVerifySession}
        transitiveOpen={true}
        transitiveClose={summaryOpen}
        title="Verify Roblox Account"
        additionalStyles={{
          minWidth: "350px",
          minHeight: "150px",
        }}
      >
        <p className="mb-2">
          To protect your account, you need to verify your session before you
          can purchase or open crates.
        </p>
        <input
          type="text"
          className="form"
          readOnly={true}
          id="copyVerificationWords"
          onClick={(e) => {
            e.target.select();
            document.execCommand("copy");
            document.getSelection().removeAllRanges();
          }}
          value={verifyWords}
        />
        <div
          className="text-xs font-semibold ml-1"
          onClick={() => {
            document.getElementById("copyVerificationWords").click();
          }}
        >
          Click to copy
        </div>
        <p className="mt-2">
          To verify your session, copy the words above and place them into your{" "}
          <a
            target="_blank"
            href={verifySessionData && verifySessionData.profileUrl}
            className="text-blue-500 hover:underline"
          >
            ROBLOX profile
          </a>{" "}
          'About' section.
        </p>
        <p className="text-center text-lg text-red-500 mt-2">
          Do not close this page.
        </p>
        <div className="w-full flex justify-end mt-2">
          <button className="button danger thin" onClick={closeVerifySession}>
            Cancel
          </button>
        </div>
      </Modal>

      <Modal
        open={errorOpen}
        onClose={closeError}
        title="Error"
        transitiveOpen={true}
        additionalStyles={{
          minWidth: "350px",
          minHeight: "150px",
        }}
      >
        <p className="text-red-400 font-medium text-center text-lg">
          {errorMessage}
        </p>
        <div className="w-full flex justify-end mt-2">
          <button className="button danger thin" onClick={closeError}>
            Close
          </button>
        </div>
      </Modal>

      <Modal
        open={summaryOpen}
        onClose={closeCrateSummary}
        transitiveClose={
          errorOpen ||
          purchaseOpen ||
          verifySessionOpen ||
          crateResultOpen ||
          wheelModalOpen
        }
        title={targetCrate && targetCrate.name + " Odds Summary"}
        additionalStyles={{
          minWidth: "375px",
          minHeight: "200px",
        }}
      >
        {/* create a table with Percentage and Prize */}
        {targetCrate && (
          <>
            <table className="table-fixed">
              <thead>
                <tr>
                  <th>Chance (%)</th>
                  <th>Prize</th>
                </tr>
              </thead>
              <tbody>
                {targetCrate.odds.map((odd, idx) => (
                  <tr key={idx}>
                    <td>{odd.chance}%</td>
                    <td>
                      {odd.prizeType === 0
                        ? odd.prize + " R$"
                        : odd.prizeType === 1
                        ? "(1) " +
                          typesData.data.find((crate) => crate.id === odd.prize)
                            .name
                        : "--"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="w-full flex justify-end mt-2">
              <button
                className="button danger thin"
                onClick={closeCrateSummary}
              >
                Close
              </button>
              {summaryMode === "purchase" && (
                <ActionButton
                  className="button primary thin ml-2"
                  loading={pendingAction}
                  onClick={() => {
                    purchaseCrate();
                  }}
                >
                  Purchase
                </ActionButton>
              )}
              {summaryMode === "open" && (
                <ActionButton
                  className="button primary thin ml-2"
                  loading={pendingAction}
                  onClick={() => {
                    openCrate(openId, targetCrate.id);
                  }}
                >
                  Open
                </ActionButton>
              )}
            </div>
          </>
        )}
      </Modal>
      <div className="card">
        <div className="body">
          <div className="text-2xl font-semibold">
            <i className="fad fa-treasure-chest"></i>&nbsp; Crates
          </div>
          <div>Win ROBUX from mysterious boxes!</div>
          <div className="text-2xl font-semibold mt-2">Crate Emporium</div>
          <div>Purchase a crate.</div>
          <div className="grid mobile-only:grid-cols-2 md:grid-cols-3 md:grid-rows-2 lg:grid-cols-4 lg:grid-rows-1 gap-4 mt-2 justify-items-center">
            {typesLoading &&  <PulseDot
                      color="#555555"
                      style={{
                        fontSize: "3rem",
                      }}
                    />}
            {typesData &&
              // only map types where purchasable is true
              typesData.data
                .filter((type) => type.purchasable)
                .map((type, idx) => (
                  <div className="card flex max-w-60" key={idx}>
                    <div className="body text-center">
                      <div className="text-xl font-semibold">
                        <img
                          className="max-w-40 mx-auto"
                          alt="Crate image"
                          src={`${type.imagePath}`}
                        />
                        <i className="fad fa-crate"></i>&nbsp; {type.name}
                      </div>
                      <div className="text-lg font-normal">
                        <i className="fa-solid fa-money-bill-simple-wave mr-2"></i>
                        {type.price} R$
                      </div>
                      <button
                        type="button"
                        className="button primary thin mt-auto"
                        disabled={!type.purchasable}
                        onClick={() => openCrateSummary(type.id, "purchase")}
                      >
                        Purchase
                      </button>
                    </div>
                  </div>
                ))}
            {typesData && typesData.data.length === 0 && (
              <div className="text-center col-span-full py-8">
                <div className="text-lg font-normal">
                  There are currently no crates available to purchase.
                </div>
              </div>
            )}
          </div>
          <div className="text-2xl font-semibold mt-4">My Inventory</div>
          <div>Crates you purchased, or given to you in promotion.</div>
          <div className="grid mobile-only:grid-cols-2 mobile-only:grid-rows-2 md:grid-cols-3 md:grid-rows-2 lg:grid-cols-4 lg:grid-rows-1 gap-4 mt-2 justify-items-center">
            {inventoryLoading &&       <PulseDot
                      color="#555555"
                      style={{
                        fontSize: "3rem",
                      }}
                    />}
            {/* map inventoryData.data.type to a crate type */}
            {inventoryData &&
              // map inventoryData.data.type to a crate type
              inventoryData.data.map((crate, idx) => (
                // get crate name
                <div className="card max-w-60" key={idx}>
                  <div className="body text-center">
                    <div className="text-xl font-semibold">
                      <img
                        className="max-w-40 mx-auto"
                        alt="Crate image"
                        src={`${crate.crate.imagePath}`}
                      />
                      <i className="fad fa-crate"></i>&nbsp; {crate.crate.name}
                    </div>
                    <div>
                      <div
                        className="button primary thin mt-2"
                        onClick={() => {
                          setOpenId(crate.inventoryItemId);
                          openCrateSummary(crate.crate.id, "open");
                        }}
                      >
                        Open
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {inventoryData && inventoryData.data.length === 0 && (
              <div className="text-center col-span-full py-8">
                <div className="text-lg font-normal">
                  You don't have any crates in your inventory.
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mt-5"></div>
      <div className="card">
        <div className="body">
          <div className="text-2xl font-semibold">
            <i className="fad fa-list-timeline"></i>&nbsp; Opening History
          </div>
          <div>Your past crate openings.</div>
          <table className="table-fixed mt-2">
            <thead>
              <tr>
                <th>
                  <i className="fad fa-calendar-alt mr-0.5"></i> Date
                </th>
                <th>
                  <i className="fad fa-treasure-chest mr-0.5"></i> Crate Type
                </th>
                <th>
                  <i className="fad fa-award mr-0.5"></i> Prize
                </th>
              </tr>
            </thead>
            <tbody>
              {crateHistoryData !== undefined &&
                crateHistoryData.history.length >= 1 &&
                crateHistoryData.history.map((crate, index) => (
                  <tr key={index}>
                    <td>{TableDisplayDate(crate.openTime)}</td>
                    <td className="font-bold uppercase">
                      { typesData &&
                        typesData.data.find((crat) => crat.id === crate.crateId) ? typesData.data.find((crat) => crat.id === crate.crateId)
                          .name : "Unknown"
                      }
                    </td>
                    <td>
                      {typesData && (crate.prizeType === 0
                        ? crate.prizeValue + " R$"
                        : crate.prizeType === 1
                        ? "(1) " +
                          typesData.data.find(
                            (crat) => crat.id === crate.prizeValue
                          ) && typesData.data.find(
                            (crat) => crat.id === crate.prizeValue
                          ).name
                        : "Unknown")}
                    </td>
                  </tr>
                ))}
              {crateHistoryLoading && crateHistoryData === undefined && (
                <tr>
                  <td colSpan={3} rowSpan={5}>
                    <PulseDot
                      color="#555555"
                      style={{
                        fontSize: "3rem",
                      }}
                    />
                  </td>
                </tr>
              )}
              {(crateHistoryError ||
                (crateHistoryData &&
                  crateHistoryData.page === 1 &&
                  crateHistoryData.history.length === 0)) && (
                <tr>
                  <td className="w-full" colSpan={3} key="1">
                    <div className="text-center text-black text-xl w-full">
                      No records found...
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="grid grid-cols-7">
            <div className="inline-block col-span-3">
              <ActionButton
                loading={
                  crateHistoryPreviousData &&
                  (crateHistoryLoading || crateHistoryFetching)
                }
                className={`button thin gray mobile-only:w-3/4 mobile-only:text-lg mt-1 pt-1 float-left ${
                  prevDisabled ? "disabled" : ""
                }`}
                disabled={prevDisabled}
                spinnerOnly={true}
                onClick={() => {
                  if (crateHistoryData.page > 1 && !crateHistoryLoading) {
                    setPage(crateHistoryData.page - 1);
                  }
                }}
              >
                Previous
              </ActionButton>
            </div>
            <div className="inline-block col-span-1 text-center mt-auto mb-auto">
              {crateHistoryData && (
                <>
                  {crateHistoryData.page} / {crateHistoryData.totalPages}
                </>
              )}
            </div>
            <div className="inline-block col-span-3">
              <ActionButton
                loading={
                  crateHistoryPreviousData &&
                  (crateHistoryLoading || crateHistoryFetching)
                }
                className={`button thin gray mobile-only:w-3/4 mobile-only:text-lg mt-1 pt-1 float-right${
                  nextDisabled ? " disabled" : ""
                }`}
                disabled={nextDisabled}
                spinnerOnly={true}
                onClick={() => {
                  if (
                    crateHistoryData.page < crateHistoryData.totalPages &&
                    !crateHistoryLoading
                  ) {
                    setPage(crateHistoryData.page + 1);
                  }
                }}
              >
                Next
              </ActionButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Crates;
