import React from "react";

class Tabs extends React.Component {
    render() {
        return (
            <span>
                <div className="tabs">
                    {
                        this.props.tabs.map(tab => {
                            const active = (tab === this.props.selected ? "active " : "");

                            return (
                                <button className={ "tab-item " + active } key={ tab } onClick={ () => this.props.setSelected(tab) }>
                                    { tab }
                                </button>
                            );
                        })
                    }
                </div>
                <div className="body">
                    { this.props.children }
                </div>
            </span>
        );
    }
}

export default Tabs;