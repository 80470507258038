import Tabs from "../tabs/Tabs";
import Tab from "../tabs/Tab";
import Modal from "../modals/Modal";
import React, { useState } from "react";
import { useQuery } from "react-query";
import {
  getGiftcardTypes,
  getPlaceLink,
  getWithdrawalHistory,
  getWithdrawInfo,
  postGroupWithdraw,
  postRedeemGiftcard,
  postVerificationRequest,
  postViewGiftcard,
  postVipWithdraw,
  processError,
} from "../../lib/API";
import PulseDot from "react-pulse-dot";
import ActionButton from "../buttons/ActionButton";
import { useInterval } from "../../utils/useInterval";
import { useAuth } from "../../lib/AuthContext";
import { useQueryClient } from "../../lib/QueryContext";
import { TableDisplayDate } from "../../utils/TableDisplayDate";

function Withdraw() {
  const [selected, setSelected] = useState("ROBUX");

  const [selectedGiftcard, setSelectedGiftcard] = useState(null);
  const [giftcardPurchaseOpen, setGiftcardPurchaseOpen] = useState(false);
  const [giftcardResultOpen, setGiftcardResultOpen] = useState(false);
  const [giftcardResult, setGiftcardResult] = useState(null);

  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [giftcardPending, setGiftcardPending] = useState(false);
  const [withdrawRobuxPending, setWithdrawRobuxPending] = useState(false);

  const [verifySessionOpen, setVerifySessionOpen] = useState(false);
  const [verifySessionData, setVerifySessionData] = useState(null);
  const [verifyWords, setVerifyWords] = useState("");

  const [withdrawRobuxError, setWithdrawRobuxError] = useState(null);
  const [withdrawRobuxOpen, setWithdrawRobuxOpen] = useState(false);
  const [withdrawRobuxSuccessAmount, setWithdrawRobuxSuccessAmount] =
    useState(0);
  const [withdrawRobuxSuccessOpen, setWithdrawRobuxSuccessOpen] =
    useState(false);
  const [withdrawRobuxAmount, setWithdrawRobuxAmount] = useState(7);
  const [customAmountInput, setCustomAmountInput] = useState("");

  const [robuxWithdrawMode, setRobuxWithdrawMode] = useState("group");

  const [isViewGiftcard, setIsViewGiftcard] = useState(false);
  const [viewGiftcardPending, setViewGiftcardPending] = useState(false);

  const [withdrawGroupSelection, setWithdrawGroupSelection] = useState(null);
  const [withdrawGroup, setWithdrawGroup] = useState(null);

  const [page, setPage] = useState(0);

  const [withdrawRobuxStep, setWithdrawRobuxStep] = useState(1);
  const [withdrawConfigureLink, setWithdrawConfigureLink] = useState(null);

  const { refreshUser, user } = useAuth();
  const { queryClient } = useQueryClient();

  const {
    isLoading: giftcardsLoading,
    error: giftcardsError,
    data: giftcardsData,
    isFetching: giftcardsFetching,
  } = useQuery(["giftcardTypes"], () =>
    getGiftcardTypes().then((res) => {
      return res.data;
    })
  );

  const {
    isLoading: withdrawInfoLoading,
    error: withdrawInfoError,
    data: withdrawInfoData,
    isFetching: withdrawInfoFetching,
  } = useQuery(["withdrawInfo"], () =>
    getWithdrawInfo().then((res) => {
      return res.data;
    })
  );

  const {
    isLoading: withdrawHistoryLoading,
    error: withdrawHistoryError,
    data: withdrawHistoryData,
    isFetching: withdrawHistoryFetching,
    isPreviousData: withdrawHistoryPreviousData,
  } = useQuery(
    ["withdrawHistory", page],
    () =>
      getWithdrawalHistory(page).then((res) => {
        return res;
      }),
    { keepPreviousData: true }
  );

  const [viewGiftcardWithdrawal, setViewGiftcardWithdrawal] = useState(null);

  const nextDisabled =
    withdrawHistoryLoading ||
    withdrawHistoryError ||
    (withdrawHistoryData &&
      withdrawHistoryData.page === withdrawHistoryData.totalPages);
  const prevDisabled =
    withdrawHistoryLoading ||
    withdrawHistoryError ||
    (withdrawHistoryData && withdrawHistoryData.page === 1);

  useInterval(async () => {
    await updateVerification();
  }, 1000);

  const withdrawalType = (withdrawal) => {
    // 0 = group
    // 1 = vip
    // 2 = gamepass
    // 3 = giftcard
    switch (withdrawal.withdrawType) {
      case 0:
        return "Group Payout";
      case 1:
        return "Vip Server";
      case 2:
        return "Game Pass";
      case 3:
        return withdrawal.value;
      default:
        return "Unknown";
    }
  };

  const updateVerification = async () => {
    if (verifySessionOpen && verifySessionData !== null) {
      var verifyReqId = verifySessionData.verificationRequestId;
      try {
        var verifyReq = await postVerificationRequest(verifyReqId);
        if (verifyReq.success) {
          // see if isVerified is true
          if (verifyReq.isVerified) {
            if (selectedGiftcard !== null) {
              // means gift card mode, else is robux
              setGiftcardPurchaseOpen(true);
              setVerifySessionOpen(false);
              setVerifySessionData(null);
              redeemGiftcard();
            } else if (viewGiftcardWithdrawal !== null) {
              setVerifySessionOpen(false);
              setVerifySessionData(null);
              viewGiftcard(viewGiftcardWithdrawal);
            }
            }
        } else {
          switch (verifyReq.error) {
            case "ERR_REQ_EXPIRED":
              setErrorMessage(
                "Your verification request has expired. Please try again."
              );
              break;
            case "ERR_REQ_NOT_FOUND":
              setErrorMessage(
                "Your verification request could not be found. Please try again."
              );
              break;
            case "ERR_SESSION_ALREADY_VERIFIED":
              setErrorMessage(
                "Your session has already been verified. Please try again."
              );
              break;
            default:
              setErrorMessage(
                "An unknown error has occurred. Please try again."
              );
              break;
          }
          setErrorOpen(true);
          setVerifySessionOpen(false);
        }
      } catch (err) {
        var error = processError(err);
        setErrorMessage(error.error);
        setErrorOpen(true);
        setVerifySessionOpen(false);
        setVerifySessionData(null);
      }
    }
  };

  const openVerifySession = async (caller) => {
    try {
      // make inital req to start verification request
      var initV = await postVerificationRequest();
      if (initV.success) {
        setVerifyWords(initV.verifyWords);
        setVerifySessionData(initV);

        if (caller === "giftcard") {
          setVerifySessionOpen(true);
          setGiftcardPurchaseOpen(false);
        }
        if (caller == "viewGiftcard")
        {
            setVerifySessionOpen(true);
        }
      }
    } catch (err) {
      var error = processError(err);
      setErrorMessage(error.error);
      setErrorOpen(true);
      setVerifySessionOpen(false);
      setSelectedGiftcard(null);
    }
  };

  const redeemGiftcard = async () => {
    setGiftcardPending(true);
    // attempt to purchase
    try {
      var response = await postRedeemGiftcard(selectedGiftcard.id);
      if (response.success) {
        // we have gift card value
        await refreshUser();
        queryClient.invalidateQueries(["giftcardTypes"]);
        setGiftcardResult(response.giftcardRedemption);
        setGiftcardResultOpen(true);
        setGiftcardPurchaseOpen(false);
        setSelectedGiftcard(null);
      } else {
        var specialError = false;
        switch (response.error) {
          case "ERR_GIFTCARD_NOT_FOUND":
            setErrorMessage(
              "The giftcard you attempted to purchase could not be found."
            );
            break;
          case "ERR_GIFTCARD_STOCK_UNAVAILABLE":
            setErrorMessage(
              "The giftcard you attempted to purchase is no longer available."
            );
            break;
          case "ERR_NOT_ENOUGH_BALANCE":
            setErrorMessage(
              "You do not have enough points to purchase this giftcard."
            );
            break;
            case "ERR_USER_WITHDRAW_DISABLED":
              setErrorMessage(
                "Your account has been disabled from withdrawing."
              );
              break;
          case "ERR_SESSION_UNVERIFIED":
            specialError = true;
            openVerifySession("giftcard");
            break;
          default:
            setErrorMessage("An unknown error occurred.");
            break;
        }

        if (!specialError) {
          setErrorOpen(true);
          setGiftcardPurchaseOpen(false);
          setSelectedGiftcard(null);
        }
      }
    } catch (err) {
      const error = processError(err);
      console.log("caught");
      setErrorMessage(error.error);
      setGiftcardPending(false);
      setGiftcardPurchaseOpen(false);
      setErrorOpen(true);
      setSelectedGiftcard(null);
    }
    setGiftcardPending(false);
  };

  const closeError = () => {
    setErrorOpen(false);
    setErrorMessage("Unknown error");
  };

  const closeGiftcardResult = () => {
    setGiftcardResultOpen(false);
    setGiftcardResult(null);
    setIsViewGiftcard(false);
  };

  const closeVerifySession = () => {
    setVerifySessionOpen(false);
    setVerifySessionData(null);
  };

  const closeWithdrawRobux = () => {
    setWithdrawRobuxOpen(false);
    setWithdrawRobuxStep(1);
    setWithdrawRobuxAmount("");
    setWithdrawRobuxError(null);
  };

  const quickRobuxAmount = (amount) => {
    if (withdrawInfoData) {
      setWithdrawRobuxAmount(amount);
      setWithdrawRobuxOpen(true);
    }
  };

  const viewGiftcard = async (withdrawal) => {
    setViewGiftcardPending(true);
    try {
      var res = await postViewGiftcard(withdrawal.withdrawId);
      if (res.success) {
        setIsViewGiftcard(true);
        setGiftcardResult(res.value);
        setGiftcardResultOpen(true);
      } else {
        var specialError = false;
        switch (res.error) {
          case "ERR_SESSION_UNVERIFIED":
            specialError = true;
            setViewGiftcardWithdrawal(withdrawal);
            openVerifySession("viewGiftcard");
            break;
          case "ERR_WITHDRAWAL_NOT_FOUND":
            setErrorMessage(
              "The giftcard you attempted to view could not be found."
            );
            break;
          default:
            setErrorMessage("An unknown error occurred.");
            break;
        }
        if (!specialError) {
          setErrorOpen(true);
        }
      }
    } catch (err) {
      var error = processError(err);
      setErrorMessage(error.error);
      setErrorOpen(true);
    }
    setViewGiftcardPending(false);
  };

  return (
    <div className="mt-10">
      <div className="card has-tabs">
        <Tabs
          tabs={["ROBUX", "Giftcards"]}
          selected={selected}
          setSelected={setSelected}
        >
          <Tab isSelected={selected === "ROBUX"}>
            <div className="text-2xl font-semibold">
              <i className="fad fa-money-from-bracket"></i>&nbsp; Withdraw Robux
            </div>
            <div>Redeem the points you have earned in exchange for ROBUX!</div>
            <div className="grid xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 text-center mt-3">
              <div className="card w-46">
                <div className="body">
                  <img
                    className="rounded-lg shadow-sm h-32 mx-auto"
                    src="../assets/img/robux.png"
                    alt="Robux image"
                  />
                  <div className="h-5"></div>
                  <div className="text-xl font-semibold">10 R$</div>
                  <div className="h-2"></div>
                  <button
                    className="button primary w-auto block"
                    onClick={() => quickRobuxAmount(10)}
                  >
                    Redeem
                  </button>
                </div>
              </div>
              <div className="card w-46">
                <div className="body">
                  <img
                    className="rounded-lg shadow-sm h-32 mx-auto"
                    src="../assets/img/robux.png"
                    alt="Robux image"
                  />
                  <div className="h-5"></div>
                  <div className="text-xl font-semibold">25 R$</div>
                  <div className="h-2"></div>
                  <button
                    className="button primary w-auto block"
                    onClick={() => quickRobuxAmount(25)}
                  >
                    Redeem
                  </button>
                </div>
              </div>
              <div className="card w-46">
                <div className="body">
                  <img
                    className="rounded-lg shadow-sm h-32 mx-auto"
                    src="../assets/img/robux.png"
                    alt="Robux image"
                  />
                  <div className="h-5"></div>
                  <div className="text-xl font-semibold">100 R$</div>
                  <div className="h-2"></div>
                  <button
                    className="button primary w-auto block"
                    onClick={() => quickRobuxAmount(100)}
                  >
                    Redeem
                  </button>
                </div>
              </div>
              <div className="card w-46">
                <div className="body">
                  <img
                    className="rounded-lg shadow-sm h-32 mx-auto"
                    src="../assets/img/robux.png"
                    alt="Robux image"
                  />
                  <div className="h-5"></div>
                  <div className="text-xl font-semibold">250 R$</div>
                  <div className="h-2"></div>
                  <button
                    className="button primary w-auto block"
                    onClick={() => quickRobuxAmount(250)}
                  >
                    Redeem
                  </button>
                </div>
              </div>
              <div className="card w-46 mobile-only:col-span-2">
                <div className="body">
                  <img
                    className="rounded-lg shadow-sm h-32 mx-auto"
                    src="../assets/img/robux.png"
                    alt="Robux image"
                  />
                  <div className="h-5"></div>
                  <div className="text-xl font-semibold">500 R$</div>
                  <div className="h-2"></div>
                  <button
                    className="button primary w-auto block"
                    onClick={() => quickRobuxAmount(500)}
                  >
                    Redeem
                  </button>
                </div>
              </div>
            </div>

            <div className="section-div pt-4 pb-4">
              OR ENTER A CUSTOM AMOUNT
            </div>

            <div className="flex">
              <input
                type="number"
                className="form mr-3"
                placeholder="Amount to withdraw..."
                value={customAmountInput}
                onChange={(e) => setCustomAmountInput(e.target.value)}
              />
              <button
                className="button primary"
                onClick={() => {
                  quickRobuxAmount(customAmountInput);
                }}
              >
                Withdraw
              </button>
            </div>
          </Tab>
          <Tab isSelected={selected === "Giftcards"}>
            <div className="text-2xl font-semibold">
              <i className="fad fa-gift-card"></i>&nbsp; Giftcards
            </div>
            <div>Redeem the points you have earned for a giftcard!</div>
            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4 text-center mt-2 min-h-[8rem]">
              {giftcardsLoading && (
                <PulseDot
                  color="#555555"
                  style={{
                    fontSize: "3rem",
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              )}
              {giftcardsData && giftcardsData.data.length === 0 && (
                <div className="text-center col-span-full row-span-full">
                  <div className="text-xl font-semibold mt-8">No giftcards found</div>
                  </div>
                  )}
              {giftcardsData &&
                giftcardsData.data.map((giftcard) => (
                  <div key={giftcard.id}>
                    <div className="card">
                      <div className="body text-center h-full">
                        <img
                          className="rounded-lg shadow-sm h-32 mx-auto"
                          src={
                            giftcard.imageSrc.startsWith("http")
                              ? giftcard.imageSrc
                              : `../assets/img/giftcards/${giftcard.imageSrc}`
                          }
                          alt="Giftcard logo"
                        />
                        <div className="h-1"></div>
                        <div className="text-xl font-semibold">
                          {giftcard.name}
                        </div>
                        <span>{giftcard.price.toLocaleString()} Points</span>
                        <div>
                          <span
                            className={
                              giftcard.stockCount === 0
                                ? "text-red-500"
                                : "text-green-500"
                            }
                          >
                            {giftcard.stockCount > 0
                              ? giftcard.stockCount + " In Stock"
                              : "Out of Stock"}
                          </span>
                        </div>
                        <div className="h-1"></div>
                        <button
                          className="button primary w-auto text-center "
                          disabled={giftcard.stockCount === 0}
                          onClick={() => {
                            console.log(giftcard.id);
                            setSelectedGiftcard(giftcard);
                            setGiftcardPurchaseOpen(true);
                          }}
                        >
                          Redeem
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </Tab>
        </Tabs>
        <Modal
          open={giftcardPurchaseOpen}
          transitiveOpen={verifySessionOpen}
          transitiveClose={errorOpen || giftcardResultOpen || verifySessionOpen}
          onClose={() => {
            if (!giftcardPending) {
              setGiftcardPurchaseOpen(false);
              setSelectedGiftcard(null);
            }
          }}
          title={selectedGiftcard && "Redeeming " + selectedGiftcard.name}
          additionalStyles={{
            minWidth: "350px",
            minHeight: "150px",
          }}
        >
          {selectedGiftcard && (
            <div className="text-lg font-medium">
              Are you sure you want to redeem{" "}
              {selectedGiftcard.price.toLocaleString()} points for '
              {selectedGiftcard.name}'?
            </div>
          )}
          <div className="w-full flex justify-end mt-2">
            <button
              className="button danger thin"
              onClick={() => {
                if (!giftcardPending) {
                  setGiftcardPurchaseOpen(false);
                  setSelectedGiftcard(null);
                }
              }}
            >
              Cancel
            </button>
            <ActionButton
              className="button primary thin ml-2"
              loading={giftcardPending}
              onClick={redeemGiftcard}
            >
              Redeem
            </ActionButton>
          </div>
        </Modal>
        <Modal
          open={giftcardResultOpen}
          onClose={closeGiftcardResult}
          title="Giftcard Redemption"
          transitiveOpen={giftcardPurchaseOpen || verifySessionOpen}
          additionalStyles={{
            minWidth: "350px",
            minHeight: "150px",
          }}
        >
                        {!isViewGiftcard && 
          <p className="font-normal text-md">
            <span className="text-green-500">Success!</span> Your giftcard has
            arrived.
          </p>}
          <div>
            <textarea
              type="text"
              className="form"
              rows="2"
              readOnly="readOnly"
              id="copyGiftcard"
              value={giftcardResult}
              onClick={(e) => {
                e.target.select();
                document.execCommand("copy");
                document.getSelection().removeAllRanges();
              }}
            ></textarea>

            <div
              className="text-xs font-semibold ml-1"
              onClick={() => {
                document.getElementById("copyGiftcard").click();
              }}
            >
              Click to copy
            </div>
          </div>
          <p className="font-normal text-md mt-2">
            You can view this giftcard again in your withdrawal history. If you
            have any problems, check our help guide on how to contact us.
          </p>
          <div className="w-full flex justify-end mt-2">
            <button
              className="button danger thin"
              onClick={closeGiftcardResult}
            >
              Close
            </button>
          </div>
        </Modal>
        <Modal
          open={errorOpen}
          onClose={closeError}
          title="Error"
          transitiveOpen={true}
          additionalStyles={{
            minWidth: "350px",
            minHeight: "150px",
          }}
        >
          <p className="text-red-400 font-medium text-center text-lg">
            {errorMessage}
          </p>
          <div className="w-full flex justify-end mt-2">
            <button className="button danger thin" onClick={closeError}>
              Close
            </button>
          </div>
        </Modal>
        <Modal
          open={verifySessionOpen}
          onClose={closeVerifySession}
          transitiveOpen={true}
          transitiveClose={giftcardPurchaseOpen || errorOpen}
          title="Verify Roblox Account"
          additionalStyles={{
            minWidth: "350px",
            minHeight: "150px",
          }}
        >
          <p className="mb-2">
            To protect your account, you need to verify your session before you
            can purchase or open crates.
          </p>
          <input
            type="text"
            className="form"
            readOnly={true}
            id="copyVerificationWords"
            onClick={(e) => {
              e.target.select();
              document.execCommand("copy");
              document.getSelection().removeAllRanges();
            }}
            value={verifyWords}
          />
          <div
            className="text-xs font-semibold ml-1"
            onClick={() => {
              document.getElementById("copyVerificationWords").click();
            }}
          >
            Click to copy
          </div>
          <p className="mt-2">
            To verify your session, copy the words above and place them into
            your{" "}
            <a
              target="_blank"
              href={verifySessionData && verifySessionData.profileUrl}
              className="text-blue-500 hover:underline"
            >
              ROBLOX profile
            </a>{" "}
            'About' section.
          </p>
          <p className="text-center text-lg text-red-500 mt-2">
            Do not close this page.
          </p>
          <div className="w-full flex justify-end mt-2">
            <button className="button danger thin" onClick={closeVerifySession}>
              Cancel
            </button>
          </div>
        </Modal>
        <Modal
          open={withdrawRobuxOpen}
          transitiveClose={withdrawRobuxSuccessOpen}
          onClose={closeWithdrawRobux}
          title={"Withdraw R$"}
          additionalStyles={{
            minWidth: "410px",
            minHeight: "150px",
          }}
        >
          <div className="text-center">
            <div className="font-semibold text-xl">
              {robuxWithdrawMode == "vip" && "VIP Withdraw"}
              {robuxWithdrawMode == "group" && "Group Withdraw"}
            </div>
            <div className="text-lg font-medium">
              Stock:{" "}
              <span className="text-green-500">
                {robuxWithdrawMode == "vip" &&
                  withdrawInfoData &&
                  withdrawInfoData.vipStock}
                {robuxWithdrawMode == "group" &&
                  withdrawInfoData &&
                  withdrawInfoData.groupStock}{" "}
                R$
              </span>
            </div>
            {withdrawRobuxStep === 1 && (
              <>
                <div className="text-md">
                  {robuxWithdrawMode == "vip" &&
                    "We will buy your VIP Server, after we buy it ROBLOX will hold the R$ for 5-7 days before it is available to you."}
                  {robuxWithdrawMode == "group" &&
                    "Group payout to your ROBLOX account, you must be in the group for 2 weeks before ROBLOX will allow you to withdraw it."}
                </div>
                <input
                  type="number"
                  className="form lg mt-2"
                  id="withdrawAmount"
                  placeholder="R$ Amount (min 7 - max 500)"
                  value={withdrawRobuxAmount}
                  onChange={(e) => {
                    setWithdrawRobuxAmount(e.target.value);
                  }}
                  min={7}
                  max={500}
                />
              </>
            )}
            {withdrawRobuxStep === 2 && (
              <>
                {robuxWithdrawMode == "vip" && (
                  <>
                    <video
                      autoPlay
                      muted
                      loop
                      controls
                      className="h-52 w-full mt-2"
                    >
                      <source
                        src={"/assets/video/withdraw.mp4"}
                        type="video/mp4"
                      />
                    </video>
                    <div className="text-md mt-2 text-xl font-medium">
                      Change the price of your VIP Server to{" "}
                      <span className="text-green-500 font-bold">
                        {Math.floor(withdrawRobuxAmount * 1.43)} R$
                      </span>
                    </div>
                    {withdrawConfigureLink && (
                      <a target="_blank" href={withdrawConfigureLink}>
                        <button className="button warning thinner mt-2 w-full">
                          Configure Place
                        </button>
                      </a>
                    )}
                  </>
                )}
                {robuxWithdrawMode == "group" && (
                  <>
                    <div className="text-md text-lg font-medium">
                      You must be in the group and have a member for atleast 2
                      weeks before ROBLOX will allow you to withdraw.
                    </div>
                    <div className="!text-left mt-1" htmlFor="withdrawGroup">
                      Group to withdraw from:
                    </div>
                    <select
                      className="form lg select"
                      id="withdrawGroup"
                      name="withdrawGroup"
                      value={withdrawGroupSelection}
                      onChange={(e) => {
                        // find group by stockerId which is e.target.value
                        const group = withdrawInfoData.groups.find(
                          (g) => g.stockerId == e.target.value
                        );
                        setWithdrawGroupSelection(e.target.value);
                        setWithdrawGroup(group);
                      }}
                    >
                      {withdrawInfoData &&
                        withdrawInfoData.groups.map((group, idx) => (
                          <option value={group.stockerId} key={idx}>
                            {group.name} ({group.stock} R$)
                          </option>
                        ))}
                    </select>
                    {withdrawGroup && (
                      <a href={withdrawGroup.link} target="_blank">
                        <button className="button warning thinner mt-2 w-full">
                          Join Group
                        </button>
                      </a>
                    )}
                  </>
                )}
              </>
            )}
            {withdrawRobuxStep === 3 && (
              <>
                <div className="text-lg">
                  Are you sure you want to withdraw{" "}
                  <span className="text-green-500 font-bold">
                    {withdrawRobuxAmount} R$
                  </span>{" "}
                  to{" "}
                  <span className="text-blue-500 font-bold">
                    {user.username}
                  </span>
                  ?
                  <img src={user.avatar} className="mx-auto w-16 rounded-xl" />
                </div>
              </>
            )}
          </div>
          {withdrawRobuxStep === 1 && (
            <div className="text-center mt-2">
              <div className="inline-flex rounded-md shadow-sm" role="group">
                <button
                  type="button"
                  className={`py-2 px-4 button primary thinner rounded-l-lg !rounded-r-none !border-none`}
                  onClick={() => {
                    setWithdrawRobuxError(null);
                    setRobuxWithdrawMode("vip");
                  }}
                >
                  VIP Server
                </button>
                <button
                  type="button"
                  className={`py-2 px-4 button primary thinner rounded-r-lg !rounded-l-none !border-none`}
                  onClick={() => {
                    setWithdrawRobuxError(null);
                    setRobuxWithdrawMode("group");
                  }}
                >
                  Group Funds
                </button>
              </div>
            </div>
          )}
          <div className="text-red-400 mt-2 text-center">
            {withdrawRobuxError}
          </div>
          <div className="w-full flex justify-end mt-2">
            <div className="w-full h-6 bg-gray-200 rounded-full dark:bg-gray-300 my-auto mr-4">
              <div
                className="h-6 bg-blue-600 rounded-full dark:bg-blue-500 text-center text-xs text-gray-100 flex progress"
                style={{
                  width: 9.25 + withdrawRobuxStep * 30.25 + "%",
                }}
              >
                <span className="m-auto">Step {withdrawRobuxStep} of 3</span>
              </div>
            </div>
            <button
              className="button danger thin"
              onClick={() => {
                setWithdrawRobuxError(null);
                if (withdrawRobuxStep === 1) {
                  closeWithdrawRobux();
                } else {
                  setWithdrawRobuxStep(withdrawRobuxStep - 1);
                }
              }}
            >
              {withdrawRobuxStep === 1 ? "Close" : "Back"}
            </button>
            <ActionButton
              className="button primary thin ml-2 min-w-fit"
              loading={withdrawRobuxPending}
              onClick={async () => {
                setWithdrawRobuxError(null);
                switch (withdrawRobuxStep) {
                  case 1:
                    // make sure amount is valid
                    if (withdrawRobuxAmount < 7 || withdrawRobuxAmount > 500) {
                      setWithdrawRobuxError(
                        "Amount must be between 7 and 500 R$"
                      );
                      return;
                    }

                    // before we advance it, we need to get the configure link
                    setWithdrawRobuxPending(true);
                    if (robuxWithdrawMode == "vip") {
                      try {
                        var res = await getPlaceLink();
                        if (res.success) {
                          setWithdrawConfigureLink(res.link);
                        } else setWithdrawConfigureLink(null);
                        setWithdrawRobuxStep(withdrawRobuxStep + 1);
                      } catch (err) {
                        var error = processError(err);
                        setErrorMessage(error.error);
                        setErrorOpen(true);
                        setWithdrawRobuxOpen(false);
                      }
                    }
                    if (robuxWithdrawMode == "group") {
                      // pick the first group from withdrawInfoData.groups if there is one
                      if (
                        withdrawInfoData &&
                        withdrawInfoData.groups.length > 0
                      ) {
                        setWithdrawGroup(withdrawInfoData.groups[0]);
                        setWithdrawGroupSelection(
                          withdrawInfoData.groups[0].stockerId
                        );
                        setWithdrawRobuxStep(withdrawRobuxStep + 1);
                      } else {
                        setWithdrawRobuxError(
                          "No groups are currently available to withdraw from."
                        );
                      }
                    }
                    // moving onto step 2 and showing the video
                    break;
                  case 2:
                    // make sure the selected group has enough robux to withdraw
                    if (robuxWithdrawMode == "group") {
                      if (withdrawGroup.stock < withdrawRobuxAmount) {
                        setWithdrawRobuxError(
                          "The group does not have enough R$. Select a different group."
                        );
                        return;
                      }
                    }

                    // moving onto step 3 and confirming
                    setWithdrawRobuxStep(withdrawRobuxStep + 1);
                    break;
                  case 3:
                    // withdrawing
                    setWithdrawRobuxPending(true);
                    if (robuxWithdrawMode == "vip") {
                      try {
                        var res = await postVipWithdraw(withdrawRobuxAmount);
                        if (res.success) {
                          setWithdrawRobuxSuccessAmount(withdrawRobuxAmount);
                          setWithdrawRobuxSuccessOpen(true);
                          closeWithdrawRobux();
                        } else {
                          switch (res.error) {
                            case "ERR_NO_MATCHES":
                              setWithdrawRobuxError(
                                "No VIP server matches found. Ensure you've set atleast one VIP server's price to " +
                                  Math.floor(withdrawRobuxAmount * 1.43) +
                                  " R$."
                              );
                              break;
                            case "ERR_STOCK_UNAVAILABLE":
                              setWithdrawRobuxError(
                                "Stock is currently unavailable."
                              );
                              break;
                            case "ERR_PAYOUT_FAILED":
                              setWithdrawRobuxError("Payout failed.");
                              break;
                            case "ERR_NOT_ENOUGH_BALANCE":
                              setWithdrawRobuxError(
                                "You don't have enough points to withdraw this much."
                              );
                              break;
                            case "ERR_AMOUNT_OUTSIDE_OF_RANGE":
                              setWithdrawRobuxError(
                                "Amount must be between 7 and 500."
                              );
                              break;
                            case "ERR_USER_HAS_NO_GAMES":
                              setWithdrawRobuxError("No games found.");
                              break;
                            case "ERR_NOT_ENOUGH_STOCK":
                              setWithdrawRobuxError(
                                "We currently don't have enough stock to complete this withdrawal."
                              );
                              break;
                              case "ERR_USER_WITHDRAW_DISABLED":
                              setWithdrawRobuxError(
                                "Your account has been disabled from withdrawing."
                              );
                              break;
                            default:
                              setWithdrawRobuxError(
                                "An unknown error occurred."
                              );
                              break;
                          }
                        }
                      } catch (err) {
                        var error = processError(err);
                        setErrorMessage(error.error);
                        setErrorOpen(true);
                        closeWithdrawRobux();
                      }
                    }
                    if (robuxWithdrawMode == "group") {
                      try {
                        var res = await postGroupWithdraw(
                          withdrawGroupSelection,
                          withdrawRobuxAmount
                        );
                        if (res.success) {
                          setWithdrawRobuxSuccessAmount(withdrawRobuxAmount);
                          setWithdrawRobuxSuccessOpen(true);
                          closeWithdrawRobux();
                        } else {
                          switch (res.error) {
                            case "ERR_NOT_ENOUGH_BALANCE":
                              setWithdrawRobuxError(
                                "You don't have enough points to withdraw this much."
                              );
                              break;
                            case "ERR_AMOUNT_OUTSIDE_OF_RANGE":
                              setWithdrawRobuxError(
                                "Amount must be between 7 and 500."
                              );
                              break;
                            case "ERR_STOCKER_NOT_FOUND":
                              setWithdrawRobuxError("Group not found.");
                              break;
                            case "ERR_NOT_ENOUGH_STOCK":
                              setWithdrawRobuxError(
                                "The group does not have enough R$. Select a different group."
                              );
                              break;
                            case "ERR_GROUP_IS_DISABLED":
                              setWithdrawRobuxError(
                                "This group has been disabled."
                              );
                              break;
                            case "ERR_PAYOUT_FAILED":
                              setWithdrawRobuxError(
                                "The payout failed. Try again later."
                              );
                              break;
                            case "ERR_NOT_ELIGIBLE":
                              setWithdrawRobuxError(
                                "You haven't been in the group for 2 weeks yet."
                              );
                              break;
                            case "ERR_STOCKER_ISSUE":
                              setWithdrawRobuxError(
                                "We had an issue with the group. Select a different group."
                              );
                              break;
                            case "ERR_NOT_IN_GROUP":
                              setWithdrawRobuxError(
                                "You have to join the group first."
                              );
                              break;
                              case "ERR_USER_WITHDRAW_DISABLED":
                                setWithdrawRobuxError(
                                  "Your account has been disabled from withdrawing."
                                );
                                break;
                            default:
                              setWithdrawRobuxError(
                                "An unknown error occurred."
                              );
                              break;
                          }
                        }
                      } catch (err) {
                        var error = processError(err);
                        setErrorMessage(error.error);
                        setErrorOpen(true);
                        closeWithdrawRobux();
                      }
                    }
                    await refreshUser();
                    setWithdrawRobuxPending(false);
                    break;
                }
                setWithdrawRobuxPending(false);
              }}
            >
              {withdrawRobuxStep === 3 ? "Withdraw" : "Next"}
            </ActionButton>
          </div>
        </Modal>
        <Modal
          open={withdrawRobuxSuccessOpen}
          transitiveOpen={true}
          onClose={() => {
            setWithdrawRobuxSuccessOpen(false);
          }}
          title={"Withdrawal Complete"}
        >
          <div className="text-center font-semibold text-xl text-green-500">
            You have successfully withdrawn {withdrawRobuxSuccessAmount} R$ to
            your account.
          </div>
          <div className="w-full flex justify-end mt-2">
            <button
              className="button danger thin"
              onClick={() => {
                setWithdrawRobuxSuccessOpen(false);
              }}
            >
              Close
            </button>
          </div>
        </Modal>
        <Modal isOpen={false}>
          <div className="font-medium text-lg text-center">
            How would you like to withdraw your R$?
          </div>
          <div className="grid grid-cols-2 mt-3 gap-2">
            <div
              className="bg-blue-500 text-white text-xl text-center p-4 rounded-md cursor-pointer transform transition duration-200 hover:scale-105"
              onClick={() => {}}
            >
              Gamepass
            </div>
            <div className="bg-blue-500 text-white text-xl text-center p-4 rounded-md cursor-pointer transform transition duration-200 hover:scale-105">
              Group Funds
            </div>
          </div>
          <div className="flex justify-end pt-2 text-sm">
            <button className="button danger thin" onClick={() => {}}>
              Cancel
            </button>
          </div>
        </Modal>
        <Modal isOpen={false}>
          <div className="text-sm -mt-2">
            Make sure you created a gamepass already. If you have not created a
            gamepass, click{" "}
            <a href="/help/gamepass" className="text-blue-400 hover:underline">
              here
            </a>{" "}
            to see how.
          </div>
          <div className="text-md mt-4">
            Set your gamepass price to{" "}
            <span className="text-green-600 font-bold">6,969 R$</span>, we cover
            the 30% ROBLOX tax.!
          </div>

          <div className="text-md mt-4">
            Once you have verified your gamepass is <b>set for sale</b> at{" "}
            <span className="text-green-600 font-bold">6,969 R$</span>, press
            the button to continue.
          </div>
          <div className="flex justify-end pt-2 text-sm">
            <button className="button danger thin mr-2" onClick={() => {}}>
              Cancel
            </button>
            <button className="button primary thin" onClick={() => {}}>
              Next
            </button>
          </div>
        </Modal>
        <Modal isOpen={false}>
          <div className="text-sm mb-1 -mt-2">
            Now enter your gamepass purchase link:
          </div>
          <input
            type="text"
            className="form"
            placeholder="Gamepass purchase link..."
          />
          <div className="text-md mt-2">
            Once you have verified your gamepass is <b>set for sale</b> at{" "}
            <span className="text-green-600 font-bold">6,969 R$</span>, and you
            have also entered the purchase link, simply hit `Purchase Now`!
          </div>
          <div className="flex justify-end pt-2 text-sm">
            <button className="button warning thin mr-2" onClick={() => {}}>
              Back
            </button>
            <button className="button primary thin" onClick={() => {}}>
              Purchase Now
            </button>
          </div>
        </Modal>
      </div>
      <div className="mt-5"></div>
      <div className="card">
        <div className="body">
          <div className="text-2xl font-semibold">
            <i className="fad fa-list-timeline"></i>&nbsp; Withdrawal History
          </div>
          <div>Your past withdrawals.</div>
          <table className="table-fixed mt-2">
            <thead>
              <tr>
                <th>
                  <i className="fad fa-calendar-alt mr-0.5"></i> Date
                </th>
                <th>
                  <i className="fas fa-money-from-bracket mr-0.5"></i> Type
                </th>
                <th>
                  <i className="fad fa-piggy-bank mr-0.5"></i> Value
                </th>
              </tr>
            </thead>
            <tbody>
              {withdrawHistoryData !== undefined &&
                withdrawHistoryData.history.length >= 1 &&
                withdrawHistoryData.history.map((withdraw, index) => (
                  <tr key={index}>
                    <td>{TableDisplayDate(withdraw.time)}</td>
                    <td className="font-bold uppercase">
                      {withdrawalType(withdraw)}
                    </td>
                    <td>
                      {withdraw.withdrawType === 3 ? (
                        <button
                          className="button primary thinner"
                          onClick={() => {
                            if (!viewGiftcardPending) {
                              viewGiftcard(withdraw);
                            }
                          }}
                        >
                          View
                        </button>
                      ) : (
                        withdraw.value + " R$"
                      )}
                    </td>
                  </tr>
                ))}
              {withdrawHistoryLoading && withdrawHistoryData === undefined && (
                <tr>
                  <td colSpan={3} rowSpan={5}>
                    <PulseDot
                      color="#555555"
                      style={{
                        fontSize: "3rem",
                      }}
                    />
                  </td>
                </tr>
              )}
              {(withdrawHistoryError ||
                (withdrawHistoryData &&
                  withdrawHistoryData.page === 1 &&
                  withdrawHistoryData.history.length === 0)) && (
                <tr>
                  <td className="w-full" colSpan={3} key="1">
                    <div className="text-center text-black text-xl w-full">
                      No records found...
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="grid grid-cols-7">
            <div className="inline-block col-span-3">
              <ActionButton
                loading={
                  withdrawHistoryPreviousData &&
                  (withdrawHistoryLoading || withdrawHistoryFetching)
                }
                className={`button thin gray mobile-only:w-3/4 mobile-only:text-lg mt-1 pt-1 float-left ${
                  prevDisabled ? "disabled" : ""
                }`}
                disabled={prevDisabled}
                spinnerOnly={true}
                onClick={() => {
                  if (withdrawHistoryData.page > 1 && !withdrawHistoryLoading) {
                    setPage(withdrawHistoryData.page - 1);
                  }
                }}
              >
                Previous
              </ActionButton>
            </div>
            <div className="inline-block col-span-1 text-center mt-auto mb-auto">
              {withdrawHistoryData && (
                <>
                  {withdrawHistoryData.page} / {withdrawHistoryData.totalPages}
                </>
              )}
            </div>
            <div className="inline-block col-span-3">
              <ActionButton
                loading={
                  withdrawHistoryPreviousData &&
                  (withdrawHistoryLoading || withdrawHistoryFetching)
                }
                className={`button thin gray mobile-only:w-3/4 mobile-only:text-lg mt-1 pt-1 float-right${
                  nextDisabled ? " disabled" : ""
                }`}
                disabled={nextDisabled}
                spinnerOnly={true}
                onClick={() => {
                  if (
                    withdrawHistoryData.page < withdrawHistoryData.totalPages &&
                    !withdrawHistoryLoading
                  ) {
                    setPage(withdrawHistoryData.page + 1);
                  }
                }}
              >
                Next
              </ActionButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Withdraw;
