import { Link } from "react-router-dom";
import HelpNav from "../../navigation/HelpNav";

const Sponsorships = () => {
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
        <HelpNav />
        <div className="md:col-span-4 card">
          <div className="body">
            <h1 className="text-2xl font-bold mb-3">How do I get a sponsorship?</h1>
            <p>
              If you have a social media channel (YouTube, Twitter, Instagram,
              Discord) and would like to advertise our website for ROBUX or real
              life money, please join our Discord listed in our footer and
              create a support ticket.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sponsorships;
