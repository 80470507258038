import { useState } from "react";
import { Link } from "react-router-dom";
import { useApp } from "../../lib/AppContext";
import { useAuth } from "../../lib/AuthContext";
import "../../index.css";

const pages = [
    {
        name: "Earn",
        path: "/earn",
        icon: "fas fa-money-bill-wave",
    },
    {
        name: "Withdraw",
        path: "/withdraw",
        icon: "fas fa-money-from-bracket",
    },
    {
        name: "Codes",
        path: "/codes",
        icon: "fas fa-gift",
    },
    {
        name: "Referrals",
        path: "/referrals",
        icon: "fas fa-users-medical",
    },
    {
        name: "Giveaways",
        path: "/giveaways",
        icon: "fas fa-party-horn",
    },
    // {
    //     name: "Contest",
    //     path: "/contest",
    //     icon: "fas fa-trophy",
    // },
    {
        name: "Crates",
        path: "/crates",
        icon: "fas fa-treasure-chest",
    },
]

function NavBar({ openLogin }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const { authState, user, logout } = useAuth();
  const { appData } = useApp();

  // landing earn crates codes referrals wall withdraw giveaways
  // dummy navbar
  return (
    <nav className="navbar bg-gradient-to-l from-green-700 to-green-500 h-16 shadow-md">
      <div className="container px-3">
        <div className="flex flex-wrap">
          <div className="w-auto" style={{
            justifyItems: "center",
            alignItems: "center",
            display: "flex",
          }}>
            <Link to="/">
              <span className="sr-only">{appData.applicationInfo.appName}</span>
              <img className="h-auto w-44" src="../assets/img/logo/brand-full.png" alt="" />
            </Link>
          </div>
          <div className="px-3.5 xmd:px-0 flex-1 w-auto"></div>
          <div className="hidden px-.5 xmd:px-0 flex-shrink w-auto xmd:flex">
            {pages.map((page, index) => (
            <Link to={page.path} key={index} className="nav-item group">
              <div>
                <i className={`${page.icon} group-hover:text-gray-300 group-hover:-translate-y-0.5 transition-all`}></i>
              </div>
              <div className="group-hover:text-gray-300 group-hover:-translate-y-0.5 transition-all">
                {page.name.toUpperCase()}
              </div>
            </Link>
            ))}
           
          </div>
          <div className="md:flex-1"></div>
          {authState && (
            <div className="-m-3 p-3 px-7 items-center rounded-md hidden xmd:flex hover:cursor-pointer" onClick={logout}>
              <img src={user.avatar} className="w-10" />
              <div className="ml-3 text-base align-middle font-medium text-gray-100 hover:text-gray-300 w-full inline-flex ">
                <div className="">
                  <div className=" min-w-[1rem] max-w-[12rem] m-0">
                    <div className="truncate">{user.username}</div>
                    <div>
                      {user.balance.toLocaleString()} <span className="text-green-500">R$</span>
                    </div>
                  </div>
                </div>{" "}
              </div>
            </div>
          )}
          {!authState && (
            <div className="hidden text-center px-3.5 xmd:px-4 flex-shrink w-auto xmd:flex">
              <a className="nav-item group cursor-pointer" onClick={openLogin}>
                <div>
                  <i className="fas fa-link group-hover:text-gray-300 group-hover:-translate-y-0.5 transition-all"></i>
                </div>
                <div className="group-hover:text-gray-300 group-hover:-translate-y-0.5 transition-all">
                  LINK YOUR ACCOUNT
                </div>
              </a>
            </div>
          )}
          <div className="my-5 mx-6 xmd:hidden">
            <button
              type="button"
              id="menu-btn"
              className={`block hamburger xmd:hidden focus:outline-none ${
                isOpen ? "open" : ""
              }`}
              aria-expanded="false"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              <span className="hamburger-top"></span>
              <span className="hamburger-middle"></span>
              <span className="hamburger-bottom"></span>
            </button>
          </div>

          <div
            id="menu"
            className={`absolute top-16 z-30 inset-x-0 p-2 xmd:hidden h-full ${
              isOpen ? "" : "hidden"
            }`}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-1 pb-6 px-5">
                <div className="mt-6">
                  <nav className="grid gap-y-6">
                  {pages.map((page, index) => (
                    <Link
                      to={page.path}
                      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                      key={index}
                    >
                      <i className={page.icon}></i>
                      <span className="ml-3 text-base font-medium text-gray-900">
                        {page.name}
                      </span>
                    </Link>
                  ))}
                    {!authState && (
                      <div
                        className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                        onClick={openLogin}
                      >
                        <i className="fas fa-link"></i>
                        <span className="ml-3 text-base font-medium text-gray-900">
                          Link Your Account
                        </span>
                      </div>
                    )}
                    {authState && (
                      <div className="-m-3 p-3 flex items-center rounded-md">
                        <img src={user.avatar} className="w-10" />
                        <div className="ml-3 text-base align-middle font-medium text-gray-900 w-full inline-flex">
                          <div className="">
                            <div className="w-20 m-0">
                              <div>{user.username}</div>
                              <div>
                                {user.balance.toLocaleString()}{" "}
                                <span className="text-green-500">R$</span>
                              </div>
                            </div>
                          </div>{" "}
                          <button
                            className="button thin gray ml-auto"
                            onClick={logout}
                          >
                            Logout
                          </button>
                        </div>
                      </div>
                    )}
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
