import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useApp } from "../../../lib/AppContext";
import NotesNav from "../../navigation/NotesNav";

const Privacy = () => {
  const { appData } = useApp();
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
        <NotesNav />
        <div className="md:col-span-4 card">
          <div className="body">
            <h1 className="text-4xl mb-3">Privacy Policy</h1>
            <h1 className="text-2xl font-bold my-3">1. Introduction</h1>
            <p className="my-[1rem]">
              1.1 We are committed to safeguarding the privacy of our website
              visitors and service users.
            </p>
            <p className="my-[1rem]">
              1.2 This policy applies where we are acting as a data controller
              with respect to the personal data of our website visitors and
              service users; in other words, where we determine the purposes and
              means of the processing of that personal data.
            </p>
            <p className="my-[1rem]">
              1.3 We use cookies on our website. Insofar as those cookies are
              not strictly necessary for the provision of our website and
              services, we will ask you to consent to our use of cookies when
              you first visit our website.
            </p>
            <p className="my-[1rem]">
              1.4 In this policy, "we", "us" and "our" refer to{" "}
              {appData.applicationInfo.appName}.
            </p>

            <h1 className="text-2xl font-bold my-3">
              2. How we use your personal data
            </h1>
            <p className="my-[1rem]">2.1 In this Section 2 we have set out:</p>
            <ol className="list-decimal list-inside my-[1.5rem] ml-[3rem]">
              <li className="mb-[1rem]">
                the general categories of personal data that we may process;
              </li>
              <li className="mb-[1rem]">
                in the case of personal data that we did not obtain directly
                from you, the source and specific categories of that data;
              </li>
              <li className="mb-[1rem]">
                the purposes for which we may process personal data; and
              </li>
              <li className="mb-[1rem]">the legal bases of the processing.</li>
            </ol>
            <p className="my-[1rem]">
              2.2 We may process data about your use of our website and services
              ("usage data"). The usage data may include your IP address,
              geographical location, browser type and version, operating system,
              referral source, length of visit, page views and website
              navigation paths, as well as information about the timing,
              frequency and pattern of your service use. The source of the usage
              data is our analytics tracking system. This usage data may be
              processed for the purposes of analyzing the use of the website and
              services. The legal basis for this processing is consent or our
              legitimate interests, namely monitoring and improving our website
              and services.
            </p>
            <p className="my-[1rem]">
              2.3 We may process your personal data that is provided in the
              course of the use of our services ("service data"). The service
              data may include the timing, frequency and pattern of service use.
              The source of the service data is you. The service data may be
              processed for the purposes of operating our website, providing our
              services, ensuring the security of our website and services,
              maintaining back-ups of our databases and communicating with you.
              The legal basis for this processing is our legitimate interests,
              namely the proper administration of our website and business.
            </p>
            <p className="my-[1rem]">
              2.4 In addition to the specific purposes for which we may process
              your personal data set out in this Section 2, we may also process
              any of your personal data where such processing is necessary for
              compliance with a legal obligation to which we are subject, or in
              order to protect your vital interests or the vital interests of
              another natural person.
            </p>
            <p className="my-[1rem]">
              2.5 Please do not supply any other person's personal data to us,
              unless we prompt you to do so.
            </p>

            <h1 className="text-2xl font-bold my-3">
              3. Providing your personal data to others
            </h1>
            <p className="my-[1rem]">
              3.1 We may disclose your personal data to our insurers and/or
              professional advisers insofar as reasonably necessary for the
              purposes of obtaining or maintaining insurance coverage, managing
              risks, obtaining professional advice, or the establishment,
              exercise or defense of legal claims, whether in court proceedings
              or in an administrative or out-of-court procedure.
            </p>
            <p className="my-[1rem]">
              3.2 In addition to the specific disclosures of personal data set
              out in this Section 3, we may disclose your personal data where
              such disclosure is necessary for compliance with a legal
              obligation to which we are subject, or in order to protect your
              vital interests or the vital interests of another natural person.
              We may also disclose your personal data where such disclosure is
              necessary for the establishment, exercise or defense of legal
              claims, whether in court proceedings or in an administrative or
              out-of-court procedure.
            </p>

            <h1 className="text-2xl font-bold my-3">
              4. International transfers of your personal data
            </h1>
            <p className="my-[1rem]">
              4.1 In this Section 4, we provide information about the
              circumstances in which your personal data may be transferred to
              countries outside the European Economic Area (EEA).
            </p>
            <p className="my-[1rem]">
              4.2 The hosting facilities for our website are situated in the US,
              Europe, Canada and the UK. The European Commission has made an
              "adequacy decision" with respect to the data protection laws of
              each of these countries. Transfers to each of these countries will
              be protected by appropriate safeguards, namely EU-U.S. Privacy
              Shield Framework.
            </p>
            <p className="my-[1rem]">
              4.3 You acknowledge that personal data that you submit for
              publication through our website or services may be available, via
              the internet, around the world. We cannot prevent the use (or
              misuse) of such personal data by others.
            </p>

            <h1 className="text-2xl font-bold my-3">
              5. Retaining and deleting personal data
            </h1>
            <p className="my-[1rem]">
              5.1 This Section 5 sets out our data retention policies and
              procedure, which are designed to help ensure that we comply with
              our legal obligations in relation to the retention and deletion of
              personal data.
            </p>
            <p className="my-[1rem]">
              5.2 Personal data that we process for any purpose or purposes
              shall not be kept for longer than is necessary for that purpose or
              those purposes.
            </p>
            <p className="my-[1rem]">
              5.3 We will retain your Cookie data for no longer than 30 days,
              after which point it will be deleted. We do not retain or store
              any profile data, as this feature is not available on our site.
              For more information on your personal data in regards to our site,
              please contact us on our Discord server found in the footer of our
              website.
            </p>
            <p className="my-[1rem]">
              5.4 Notwithstanding the other provisions of this Section 5, we may
              retain your personal data where such retention is necessary for
              compliance with a legal obligation to which we are subject, or in
              order to protect your vital interests or the vital interests of
              another natural person.
            </p>

            <h1 className="text-2xl font-bold my-3">6. Amendments</h1>
            <p className="my-[1rem]">
              6.1 We may update this policy from time to time by publishing a
              new version on our website.
            </p>
            <p className="my-[1rem]">
              6.2 You should check this page occasionally to ensure you are
              happy with any changes to this policy.
            </p>
            <p className="my-[1rem]">
              6.3 We may notify you of changes to this policy by email or
              through the private messaging system on our website.
            </p>

            <h1 className="text-2xl font-bold my-3">7. Your rights</h1>
            <p className="my-[1rem]">
              7.1 In this Section 7, we have summarized the rights that you have
              under data protection law. Some of the rights are complex, and not
              all of the details have been included in our summaries.
              Accordingly, you should read the relevant laws and guidance from
              the regulatory authorities for a full explanation of these rights.
            </p>
            <p className="my-[1rem]">
              7.2 Your principal rights under data protection law are:
            </p>
            <ol className="list-decimal list-inside my-[1.5rem] ml-[3rem]">
              <li className="mb-[1rem]">the right to access;</li>
              <li className="mb-[1rem]">the right to rectification;</li>
              <li className="mb-[1rem]">the right to erasure;</li>
              <li className="mb-[1rem]">the right to restrict processing;</li>
              <li className="mb-[1rem]">the right to object to processing;</li>
              <li className="mb-[1rem]">the right to data portability;</li>
              <li className="mb-[1rem]">
                the right to complain to a supervisory authority; and
              </li>
              <li className="mb-[1rem]">the right to withdraw consent.</li>
            </ol>
            <p className="my-[1rem]">
              7.3 You have the right to confirmation as to whether or not we
              process your personal data and, where we do, access to the
              personal data, together with certain additional information. That
              additional information includes details of the purposes of the
              processing, the categories of personal data concerned and the
              recipients of the personal data. Providing the rights and freedoms
              of others are not affected, we will supply to you a copy of your
              personal data. The first copy will be provided free of charge, but
              additional copies may be subject to a reasonable fee. Provision of
              such information will be subject to:
            </p>
            <ol className="list-decimal list-inside my-[1.5rem] ml-[3rem]">
              <li className="mb-[1rem]">
                the first copy will be provided free of charge, but additional
                copies will be subjected to a $25 fee; and
              </li>
              <li className="mb-[1rem]">
                the supply of appropriate evidence of your identity (for this
                purpose, we will usually accept a photocopy of your passport
                certified by a solicitor or bank plus an original copy of a
                utility bill showing your current address).
              </li>
            </ol>
            <p className="my-[1rem]">
              7.4 You have the right to have any inaccurate personal data about
              you rectified and, taking into account the purposes of the
              processing, to have any incomplete personal data about you
              completed.
            </p>
            <p className="my-[1rem]">
              7.5 In some circumstances you have the right to the erasure of
              your personal data without undue delay. Those circumstances
              include: the personal data are no longer necessary in relation to
              the purposes for which they were collected or otherwise processed;
              you withdraw consent to consent-based processing; you object to
              the processing under certain rules of applicable data protection
              law; the processing is for direct marketing purposes; and the
              personal data have been unlawfully processed. However, there are
              exclusions of the right to erasure. The general exclusions include
              where processing is necessary: for exercising the right of freedom
              of expression and information; for compliance with a legal
              obligation; or for the establishment, exercise or defense of legal
              claims.
            </p>
            <p className="my-[1rem]">
              7.6 In some circumstances you have the right to restrict the
              processing of your personal data. Those circumstances are: you
              contest the accuracy of the personal data; processing is unlawful
              but you oppose erasure; we no longer need the personal data for
              the purposes of our processing, but you require personal data for
              the establishment, exercise or defense of legal claims; and you
              have objected to processing, pending the verification of that
              objection. Where processing has been restricted on this basis, we
              may continue to store your personal data. However, we will only
              otherwise process it: with your consent; for the establishment,
              exercise or defense of legal claims; for the protection of the
              rights of another natural or legal person; or for reasons of
              important public interest.
            </p>
            <p className="my-[1rem]">
              7.7 You have the right to object to our processing of your
              personal data on grounds relating to your particular situation,
              but only to the extent that the legal basis for the processing is
              that the processing is necessary for: the performance of a task
              carried out in the public interest or in the exercise of any
              official authority vested in us; or the purposes of the legitimate
              interests pursued by us or by a third party. If you make such an
              objection, we will cease to process the personal information
              unless we can demonstrate compelling legitimate grounds for the
              processing which override your interests, rights and freedoms, or
              the processing is for the establishment, exercise or defense of
              legal claims.
            </p>
            <p className="my-[1rem]">
              7.8 You have the right to object to our processing of your
              personal data for direct marketing purposes (including profiling
              for direct marketing purposes). If you make such an objection, we
              will cease to process your personal data for this purpose.
            </p>
            <p className="my-[1rem]">
              7.9 If you consider that our processing of your personal
              information infringes data protection laws, you have a legal right
              to lodge a complaint with a supervisory authority responsible for
              data protection. You may do so in the EU member state of your
              habitual residence, your place of work or the place of the alleged
              infringement.
            </p>
            <p className="my-[1rem]">
              7.10 To the extent that the legal basis for our processing of your
              personal information is consent, you have the right to withdraw
              that consent at any time. Withdrawal will not affect the
              lawfulness of processing before the withdrawal.
            </p>
            <p className="my-[1rem]">
              7.11 You may exercise any of your rights in relation to your
              personal data by contacting us using the Discord link found in the
              footer of our website.
            </p>

            <h1 className="text-2xl font-bold my-3">
              8. Collection of Data from Children
            </h1>
            <p className="my-[1rem]">
              8.1 COPPA is the Children's Online Privacy Protection Act. In
              order to comply with COPPA, users under the age of 13 are not
              permitted to use the website without prior parental consent. Users
              found to be under the age of 13 will be disabled from using our
              website until parental verification is provided.
            </p>
            <p className="my-[1rem]">
              8.2 We do not knowingly process or collect data or identifiable
              personal information from users under the age of 13 in accordance
              with COPPA.
            </p>
            <p className="my-[1rem]">
              8.3 We use advertisements and survey networks on our website that
              may ask for information from our users. We do not control how data
              is processed or collected on our partners' websites.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;
