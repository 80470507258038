const Alert = ({ children, type }) => {
    return ( 
       <div className={`alert ${type} grid grid-cols-12`}>
        <div className="col-span-1 text-start">
        <i className="fad fa-exclamation-circle"></i>
        </div>
        <div className="col-span-10">{children}</div>
        <div className="col-span-1 text-end">
        <i className="fad fa-exclamation-circle"></i>
        </div>
        </div>
     );
}
 
export default Alert;