import { Link, useParams } from "react-router-dom";
import { useApp } from "../../lib/AppContext";
import { useAuth } from "../../lib/AuthContext";
import NotFound from "../NotFound/NotFound";

function Wall()
{
    let { id } = useParams();
    const { appData } = useApp();
    const { user } = useAuth();
    const wall = appData.applicationData.offerWalls.find(x=>x.id==id);
    if (!wall)
    return <NotFound />;
    return (
        <>
        <div className={`card h-28 text-center flex items-center justify-center wall-${wall.name.toLowerCase()}`}>
            <img src={wall.image} className="w-52" alt="Offerwall Logo" />
        </div>
        <div className="grid lg:grid-cols-2 lg:grid-rows-1 sm:grid-rows-2 sm:grid-cols-1 mobile-only:grid-rows-2 mobile-only:grid-cols-1 mt-3 mb-3 mobile-only:gap-2 sm:gap-2">
            <Link to="/earn" className="button success sm:w-full mobile-only:w-full lg:w-2/4 text-center lg:justify-self-start">
                <i className="fas fa-arrow-left-long"></i> Go Back
            </Link>
            <Link to="/help/missing-robux" className="button danger sm:w-full mobile-only:w-full lg:w-2/4 text-center lg:justify-self-end">
                <i className="fas fa-circle-question"></i> Where's my ROBUX?
            </Link>
        </div>
        <div className="shadow-lg container">
            <iframe src={wall.url.replace("{userid}", user.id)} className="w-full offerwall-iframe aspect-auto" frameborder="0"></iframe>
        </div>
    </>
    );
}

export default Wall;