import { Link } from "react-router-dom";
import HelpNav from "../../navigation/HelpNav";

const MissingRobux = () => {
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
        <HelpNav />
        <div className="md:col-span-4 card">
          <div className="body">
            <h1 className="text-2xl font-bold mb-3">
              Why did I not receive my ROBUX after finishing an offer?
            </h1>
            <p className="font-medium">
              If you think you successfully completed your offer and did not
              receive ROBUX for it, it may have been due to one or more of the
              following reasons:
            </p>
            <ul>
              <li>The offer may take up to 24 hours to complete</li>
              <li>
                You did the offer too quickly (speeding), or lied on a survey
                offer
              </li>
              <li>You completed the offer on another account or website</li>
              <li>
                You did not complete all the steps in the offer's requirements
              </li>
              <li>You used a VPN/Proxy</li>
              <li>You used AdBlocker software</li>
              <li>
                You used 3G/4G/5G or another mobile communication standard that
                could prevent your offer from fully processing
              </li>
            </ul>
            <br />
            <p>
              <span className="font-bold text-red-500">IMPORTANT:</span> If you
              successfully completed an offer that was worth more than 2,500 R$
              and the offerwall says you did, please contact the support team on
              our Discord server listed on the{" "}
              <Link to="/earn" className="underline text-blue-400">
                earn page
              </Link>
              .
            </p>
            <h1 className="text-2xl font-bold my-3">
              Nothing above helped me, what do I do?
            </h1>
            <p>
              If nothing above helped you, you should get in contact with the
              support team of the respective offerwall.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MissingRobux;
