import { createContext, useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { checkWebErrors, getUser, postLogin, processError } from "./API";
import { useQueryCache, useQuery } from "react-query";
import { useApp } from "./AppContext";
import { useQueryClient } from "./QueryContext";

const AuthContext = createContext({});

const CheckAuthBeforeLoad = Cookies.get("kyloAuthorization") !== undefined;

export const AuthProvider = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const { queryClient } = useQueryClient();

  useEffect(() => {
    (async () => {
      if (CheckAuthBeforeLoad) {
        await userInfo();
      } else {
        setIsLoading(false);
      }
    })();
  }, []);

  const userInfo = async () => {
    try {
      var response = await getUser();
      if (response.authorized) {
        setIsLoggedIn(true);
        setUser(response);
      }
    } catch (err) {
      localLogout();
    }
    setIsLoading(false);
  };

  const localLogout = () => {
    Cookies.remove("kyloAuthorization");
    setIsLoggedIn(false);
    setUser(null);
    queryClient.clear();
  };

  const login = async (username) => {
    try {
      var response = await postLogin(username);
      var wErrors = checkWebErrors(response);
      if (response.success === false && wErrors.webError) {
        return {
          success: false,
          error: wErrors.error,
        };
      }
      if (response.success === true && response.error !== undefined) {
        switch (response.error) {
          case "USERNAME_NOT_FOUND":
            return {
              success: false,
              error: "Username not found",
            };
          case "ERR_USER_LOGIN_DISABLED":
            return {
              success: false,
              error: "This account has been disabled.",
            };
            default:
              return {
                success: false,
                error: "Unknown error",
              };
        }
      }
      if (response.success === true) {
        setUser(response);
        Cookies.set("kyloAuthorization", response.kyloAuthorization);
        await userInfo();
        return {
          success: true,
        };
      }
    } catch (err) {
      return processError(err);
    }
  };

  const logout = () => {
    localLogout();
  };

  const authContextValue = {
    authState: isLoggedIn,
    authLoading: isLoading,
    refreshUser: (e) => userInfo(),
    user,
    login,
    logout,
  };
  return <AuthContext.Provider value={authContextValue} {...props} />;
};

export const useAuth = () => useContext(AuthContext);
