import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useApp } from "../../../lib/AppContext";
import HelpNav from "../../navigation/HelpNav";

const EarningRobux = () => {
    const { appData } = useApp();
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
        <HelpNav />
        <div className="md:col-span-4 card">
          <div className="body">
            <h1 className="text-2xl font-bold mb-3">How do I earn ROBUX?</h1>
            <h1 className="text-xl font-semibold my-3">1. Offerwalls</h1>
            <p>
              Offerwalls are lists of different types of offers that you can
              complete for ROBUX. They're simple to use and have offers that
              range from watching videos to writing surveys to downloading
              mobile apps/games.
            </p>
            <h1 className="text-xl font-semibold my-3">2. Social Media</h1>
            <p>
              Social Media is the easiest way to earn some ROBUX! By following
              us on social media via the Earn page, you will receive ROBUX for
              each website you follow/join us on.
            </p>
            <h1 className="text-xl font-semibold my-3">3. Giveaways</h1>
            <p>
              Giveaways are hosted every so often on the website{" "}
              <Link to="/giveaways" className="underline text-blue-400">
                here
              </Link>
              , our Discord server, and our social media!
            </p>
            <h1 className="text-xl font-semibold my-3">4. Referrals</h1>
            <p>
              Referrals are one of the easiest ways to earn ROBUX! By referring
              your friends, family, or some stranger, you will receive{" "}
              <span className="font-semibold">{appData.applicationData.referralStatistics.referralPercentage}%</span> of their earnings
              forever! <span className="italic">*User must be new</span>
            </p>
            <h1 className="text-xl font-semibold my-3">5. Crates</h1>
            <p>
              Crates are a way to take a chance and double your earnings! Have
              some spare points on your account? Purchase one{" "}
              <Link to="/crates" className="underline text-blue-400">
                here
              </Link>{" "}
              and get a chance to double or even triple your balance!
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default EarningRobux;
