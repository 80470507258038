import { Link } from "react-router-dom";
import { useApp } from "../../lib/AppContext";
import Badge from "../badges/Badge";

function Earn() {
  const { appData } = useApp();

  var walls = appData.applicationData.offerWalls;

  var socialMedias = appData.applicationData.socialMedias;

  return (
    <>
      {appData.applicationData.earnEvent.active && (
        <>
          <div className="text-4xl font-semibold mb-3">🤑 Start Earning</div>
          <Link to={appData.applicationData.earnEvent.buttonLink}>
            <div
              className={`card min-h-32 flex items-center bg-gradient-to-r from-${appData.applicationData.earnEvent.gradient1} to-${appData.applicationData.earnEvent.gradient2} transition ease-in-out`}
            >
              <i
                className={`fa-duotone ${appData.applicationData.earnEvent.icon} text-7xl text-white ml-5`}
              ></i>
              <div className="flex flex-col ml-5">
                <div className="text-white text-xl font-semibold pt-5">
                  {appData.applicationData.earnEvent.title}
                </div>
                <div className="text-white break-all pr-5">
                  {appData.applicationData.earnEvent.description}
                </div>
                <div className="md:hidden mt-1 pb-5">
                  <i className="fa fa-arrow-right-long text-white text-4xl absolute right-7 -translate-y-5"></i>
                </div>
                <div className="hidden md:block mt-3 pb-5">
                  <div className="button thin info !text-blue-500">
                    {appData.applicationData.earnEvent.buttonText}{" "}
                    <i className="fa-solid fa-arrow-right-long ml-2"></i>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </>
      )}
      <div className={`card${appData.applicationData.earnEvent.active ? " mt-10" : ""}`}>
      <div className="body">
          <div className="text-4xl font-semibold mb-2">📋 Offerwalls</div>
          <p className="text-xl mb-3">
            Earn by completing surveys, watching videos, or playing games!
          </p>
          {walls === undefined ||
            (walls !== undefined && walls.length == 0 && (
              <>
                <div className="text-center text-xl w-full mt-10">
                  There are currently no offerwalls available
                </div>
              </>
            ))}
          <div className="grid mobile-only:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 grid-flow-row gap-4 auto-rows-auto">
            {walls !== undefined &&
              walls !== null &&
              walls.map((wall, index) => (
                <Link to={`/wall/${wall.id}`} key={index}>
                  <div
                    className={`card w-60 h-24 !bg-${wall.background} mobile-only:w-full flex items-center justify-center transform transition duration-200 hover:scale-110`}
                  >
                    {wall.badge && wall.badge.active == true && (
                      <Badge color={wall.badge.color}>{wall.badge.data}</Badge>
                    )}
                    <img
                      src={wall.image}
                      className="mobile-only:w-36 w-44"
                      alt={`${wall.name} Logo`}
                    />
                  </div>
                </Link>
              ))}
          </div>

          <div className="text-4xl font-semibold mt-10 mb-2">📝 Tasks</div>
          <p className="text-xl mb-3">
            Complete tasks below to earn weekly, daily, and sometimes hourly!
          </p>
          <div className="grid mobile-only:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 grid-flow-row gap-4 auto-rows-auto">
          <Link to="/referrals">
            <div className="card w-60 h-24 !bg-blue-400 mobile-only:w-full flex items-center justify-center transform transition duration-200 hover:scale-110">
              <div className="mobile-only:text-xl text-2xl text-white">
                <i className="fas fa-users-medical"></i> Referrals
              </div>
            </div>
          </Link>
          <Link to="/giveaways">
            <div className="card w-60 h-24 !bg-slate-700 mobile-only:w-full flex items-center justify-center transform transition duration-200 hover:scale-110">
              <div className="mobile-only:text-xl text-2xl text-white">
                <i className="fas fa-party-horn"></i> Giveaways
              </div>
            </div>
          </Link>
          <Link to="/crates">
            <div className="card w-60 h-24 !bg-blue-500 mobile-only:w-full flex items-center justify-center transform transition duration-200 hover:scale-110">
              <div className="mobile-only:text-xl text-2xl text-white">
                <i className="fas fa-treasure-chest"></i> Crates
              </div>
            </div>
          </Link>
          <Link to="/codes">
            <div className="card w-60 h-24 !bg-blue-500 mobile-only:w-full flex items-center justify-center transform transition duration-200 hover:scale-110">
              <div className="mobile-only:text-xl text-2xl text-white">
                <i className="fas fa-gift"></i> Promotions
              </div>
            </div>
          </Link> 
          </div>
          {socialMedias !== undefined && socialMedias.length > 0 && (
            <>
              <div className="text-4xl font-semibold mt-10 mb-2">
                🐦 Social Media
              </div>
              <p className="text-xl mb-3">
                Follow our social media to receive a few R$ and a chance to
                participate in daily giveaways!
              </p>
              <div className="grid mobile-only:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 grid-flow-row gap-4 auto-rows-auto">
                {socialMedias.map((socialMedia, index) => (
                  <div
                    className={`card w-60 h-24 mobile-only:w-full flex items-center justify-center transform transition duration-200 hover:scale-110`}
                    style={{ backgroundColor: socialMedia.background }}
                    key={index}
                  >
                    {socialMedia.badge && socialMedia.badge.active == true && (
                      <Badge color={socialMedia.badge.color}>
                        {socialMedia.badge.data}
                      </Badge>
                    )}
                    <img
                      src={socialMedia.image}
                      className={`w-${socialMedia.imageSize} max-w-${socialMedia.imageSize} bg-transparent`}
                      alt={`${socialMedia.name} Logo`}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Earn;
