import { TypeAnimation } from "react-type-animation";
import { useApp } from "../../lib/AppContext";
import { nFormatter } from "../../utils/nFormatter";

function Landing({onOpen}) {
    const { appData } = useApp();

    return ( 
        <div className="w-full text-center">
        <div className="card text-center h-96 p-5" id="animated-bg">
          <div className="grid grid-rows-2 mt-4 items-center content-center justify-items-center">
            <div className="text-white text-4xl h-36 md:h-16">
              Earn <span className="font-bold">FREE <span className="text-green-500">ROBUX</span> by </span>
              <TypeAnimation
                cursor={true}
                sequence={[
                  'completing offers!',
                  2000,
                  'watching videos!',
                  2000,
                  'doing surveys!',
                  2000,
                  2000,
                  'playing games!',
                  2000,
                  'entering giveaways!',
                  2000,
                  'following our socials!',
                  2000,
                  'opening crates!',
                  2000,
                  'referring friends!',
                  2000,
                ]}
                wrapper="span"
                repeat={Infinity}
              />
            </div>
            <button className="button success w-64 md:mt-[5vh] mx-auto text-lg" onClick={() => {
              onOpen();
            }}>
              Start Earning <i className="fa-solid fa-arrow-right-long ml-2"></i>
            </button>
          </div>
        </div>
  
        <div className="card mt-5">
          <div className="body">
            <div className="grid grid-rows-3 gap-4 md:grid-rows-1 md:grid-cols-3 md:gap-0">
              <div className="m-auto">
                {/* legit rbx, 1500000, 67000, 46000 */}
                {/* quick rx, 27000, 9000, 14000 */}
                <p className="text-2xl md:text-3xl text-green-500 font-semibold">{nFormatter(appData.applicationData.landingStatistics.totalAwarded+(1500000))} R$</p>
                <p className="text-sm md:text-lg font-medium"><i className="fa-solid fa-sack-dollar"></i> Awarded</p>
              </div>
              <div className="m-auto">
                <p className="text-2xl md:text-3xl text-orange-400 font-semibold">{nFormatter(appData.applicationData.landingStatistics.totalCompletedOffers+(67000))}</p>
                <p className="text-sm md:text-lg font-medium"><i className="fa-solid fa-list-check"></i> Completed Offers</p>
              </div>
              <div className="m-auto">
                <p className="text-2xl md:text-3xl text-cyan-400 font-semibold">{nFormatter(appData.applicationData.landingStatistics.totalUsers+(46000))}</p>
                <p className="text-sm md:text-lg font-medium"><i className="fa-solid fa-users"></i> Users</p>
              </div>
            </div>
          </div>
        </div>
  
        <div className="card mt-5">
          <div className="body">
            <h1 className="text-3xl font-bold mb-5">Get Started in 3 Easy Steps...</h1>
            <div className="grid grid-rows-3 gap-6 md:grid-rows-1 md:grid-cols-3 md:gap-0">
              <div className="m-auto">
                <p className="text-xl font-semibold mb-2">1. Link Your Account</p>
                <img src="./assets/img/enter_with_roblox.png" className="w-52 h-52 mx-auto" alt="Screenshot" />
              </div>
              <div className="m-auto">
                <p className="text-xl font-semibold mb-2">2. Do Earn Tasks</p>
                <img src="./assets/img/offer_wall.png" className="w-52 h-52 mx-auto" alt="Screenshot" />
              </div>
              <div className="m-auto">
                <p className="text-xl font-semibold mb-2">3. Withdraw Your ROBUX</p>
                <img src="./assets/img/withdraw_balance.png" className="w-52 h-52 mx-auto" alt="Screenshot" />
              </div>
            </div>
          </div>
        </div>
  
        <div className="card mt-5">
          <div className="body">
            <h1 className="text-3xl font-bold mb-5">How does {appData.applicationInfo.appName} work?</h1>
              <p className="text-lg font-semibold mb-2">
                When you complete offers with us, various companies pay us for your completion of them. In turn, we give you 95% of the revenue we get from each offer to you in the form of ROBUX or giftcards. The other 5% goes to the sites operating costs and giveaways!
              </p>
          </div>
        </div>
      </div>
       );
}

export default Landing;