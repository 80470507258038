import React, { useEffect } from "react";
import ReactDom from "react-dom";

const MODAL_STYLES = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "white",
  paddingLeft: ".5rem",
  paddingRight: ".5rem",
  paddingTop: "1rem",
  paddingBottom: "1rem",
  zIndex: 1000,
  border: "1px solid #ccc",
  boxShadow: "0 0 10px rgba(0, 0, 0, .5)",
  borderRadius: "5px",
};

const OVERLAY_STYLES = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: "rgba(0,0,0,.7)",
  zIndex: 999,
};

const CLOSE_BTN_STYLES = {
  position: "absolute",
  top: 0,
  right: 0,
};

const unlockScroll = () => {
  const scrollY = document.body.style.top;
  document.body.style.position = "";
  document.body.style.top = "";
  document.body.style.overflow = "";
  window.scrollTo(0, parseInt(scrollY || "0") * -1);
};

const lockScroll = () => {
  document.body.style.top = `-${window.scrollY}px`;
  document.body.style.position = "fixed";
  document.body.style.overflow = "hidden";
}

export default function Modal({
  open,
  transitiveClose = false,
  transitiveOpen = false,
  children,
  onClose,
  additionalStyles = {},
  exclusiveModal = false,
  title = "",
}) {
  useEffect(() => {
    const handleKeydown = (e) => {
      if (e.key === "Escape") {
        onClose();
      }
    };
    if (!open && !transitiveClose) {
      unlockScroll();
    }
    if (open && !transitiveOpen) {
      lockScroll();
    }
    document.addEventListener("keydown", handleKeydown);
    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [open]);

  if (!open) {
    return null;
  }

  return ReactDom.createPortal(
    <>
      <div style={OVERLAY_STYLES} onClick={onClose} />
      <div
        style={{
          ...MODAL_STYLES,
          ...additionalStyles,
        }}
      >
        <div className={!exclusiveModal ? "modal-content" : ""}>
          <div className="flex justify-between items-center pb-3">
            <p className="text-xl font-bold">{title}</p>
            {!exclusiveModal &&
            <div
              className="modal-close cursor-pointer z-50"
              onClick={onClose}
            >
              <i className="fa-solid fa-xmark text-xl"></i>
            </div>}
          </div>
          {children}
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
}
