import { Link, NavLink } from "react-router-dom";

function HelpNav() {
    return (
        <div className="text-center">
        <NavLink to="/help/getting-started">
          <div className="card p-5 !rounded-b-none help-nav">Getting Started</div>
        </NavLink>
        <NavLink to="/help/earning-robux">
          <div className="card p-5 !rounded-none !border-t-0 help-nav">Earning R$</div>
        </NavLink>
        <NavLink to="/help/withdrawing-robux" activeClassName="help-active">
          <div className="card p-5 !rounded-none !border-t-0 help-nav">
            Withdrawing R$
          </div>
        </NavLink>
        <NavLink to="/help/sponsorships" activeClassName="help-active">
          <div className="card p-5 !rounded-none !border-t-0 help-nav">
            Sponsorships
          </div>
        </NavLink>
        <NavLink to="/help/missing-robux" activeClassName="help-active">
          <div className="card p-5 !rounded-none !border-t-0 help-nav">Missing R$</div>
        </NavLink>
        <NavLink to="/help/contact-us" activeClassName="help-active">
          <div className="card p-5 !rounded-t-none !border-t-0 help-nav">
            Contact Us
          </div>
        </NavLink>
      </div>
    )
}

export default HelpNav;