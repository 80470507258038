export const TableDisplayDate = (timestamp) => {
    const date = new Date(timestamp);
    return (
      date.getUTCMonth() +
      1 +
      "/" +
      date.getUTCDate() +
      "/" +
      date.getUTCFullYear().toString().substr(2,2)
    );
  };