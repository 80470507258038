import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useApp } from "../../../lib/AppContext";
import HelpNav from "../../navigation/HelpNav";

const GettingStarted = () => {
  const { appData} = useApp();
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
        <HelpNav />
        <div className="md:col-span-4 card">
          <div className="body">
            <h1 className="text-2xl font-bold mb-3">What is this website?</h1>
            <p>
            {appData.applicationInfo.appName} is a platform that allows you to earn ROBUX without
              paying any money. We have given out hundreds of thousands in ROBUX
              payouts to our loyal users.
            </p>
            <br />
            <p>
              Most methods out there that promise you free ROBUX are{" "}
              <span className="font-semibold text-red-500">scams</span>! ROBUX
              costs real life money to purchase and cannot be generated by hacks
              or exploits.
            </p>
            <h1 className="text-2xl font-bold my-3">How is this legit?</h1>
            <p>
              So, how and why do we give away ROBUX to users? The reason is
              simple: this is because we get paid for showing you advertisements
              when you access our website.
            </p>
            <br />
            <p>
              To earn ROBUX on our website you may complete surveys, watch ads,
              download mobile apps & games or participate in our community
              giveaways. By doing this, we get paid from the surveys and ads
              that you view, and we split our earnings with you in the form of
              ROBUX that we purchase from suppliers with the ad revenue that we
              receive.
            </p>
            <h1 className="text-2xl font-bold my-3">
              Is this website free to use?
            </h1>
            <p>Our website is free-to-use and always will be!</p>
            <br />
            <h1 className="text-2xl font-bold my-3">
              Why should I use your website?
            </h1>
            <ol>
              <li>1. We restock our ROBUX quickly</li>
              <li>2. We pay more ROBUX per $1 USD than most competitors</li>
              <li>3. We host many social events and giveaways for ROBUX</li>
              <li>
                4. We have many more methods to earn than most competitors
              </li>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
};

export default GettingStarted;
