import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "./AuthContext";

function RequireAuth({ openLogin }) {
  let { authState } = useAuth();
  let location = useLocation();

  useEffect(() => {
    if (authState === false) {
      openLogin();
    }
  }, []);

  if (!authState) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return <Outlet />;
}

export default RequireAuth;
