import { Link } from "react-router-dom";
import HelpNav from "../../navigation/HelpNav";

const ContactUs = () => {
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
        <HelpNav />
        <div className="md:col-span-4 card">
          <div className="body">
            <h1 className="text-2xl font-bold mb-3">Contact Us</h1>
            <p>
              You may contact us using our Discord server or on one of our other
              social media platforms listed in the "Social Media" section on our
              the{" "}
              <Link to="/giveaways" className="underline text-blue-400">
                earn page
              </Link>
              .
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
