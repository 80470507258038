import { createContext, useContext } from "react";
import { useQuery } from "react-query";
import { getAppData } from "./API";

const AppContext = createContext({});

export const AppProvider = props => {
    const { isLoading, error, data, } = useQuery(["appData"], () => 
    getAppData()
      .then((res) =>
        res.data
      ), { keepPreviousData : true });

  const appContextValue = {
    appData: data,
    appIsLoading: isLoading,
    appError: error,
};
return   <AppContext.Provider value={appContextValue} {...props} />;
}

export const useApp = () => useContext(AppContext);