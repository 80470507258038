import { useState } from "react";
import { Link } from "react-router-dom";
import { useApp } from "../../lib/AppContext";

const Footer = () => {
    const [count, setCount] = useState(0)
    const { appData } = useApp();
  
    return (
      <footer>
        <main className="container">
          <div className="grid sm:grid-cols-2 sm:grid-rows-2 md:grid-rows-1 md:grid-cols-4 lg:grid-cols-5">
            <div className="px-3.5 md:px-4 py-3.5 md:py-4">
              <div className="opacity-80 font-bold">NAVIGATE</div>
              <Link to="/" className="text-black font-semibold opacity-90 block">
                Landing
              </Link>
              <Link to="/earn" className="text-black font-semibold opacity-90 block">
                Earn
              </Link>
              <Link to="/withdraw" className="text-black font-semibold opacity-90 block">
                Withdraw
              </Link>
              <Link to="/codes" className="text-black font-semibold opacity-90 block">
                Promotions
              </Link>
            </div>
            <div className="px-3.5 md:px-4 py-3.5 md:py-4">
              <div className="opacity-80 font-bold">HELP</div>
              <Link to="/help/getting-started" className="text-black font-semibold opacity-90 block">
                Getting Started
              </Link>
              <Link to="/help/earning-robux" className="text-black font-semibold opacity-90 block">
                Earning ROBUX
              </Link>
              <Link to="/help/missing-robux" className="text-black font-semibold opacity-90 block">
                Missing ROBUX
              </Link>
              <Link to="/help/contact-us" className="text-black font-semibold opacity-90 block">
                Contact Us
              </Link>
            </div>
            <div className="px-3.5 md:px-4 py-3.5 md:py-4">
              <div className="opacity-80 font-bold">NOTES</div>
              <Link to="/notes/terms-of-service" className="text-black font-semibold opacity-90 block">
                Terms of Service
              </Link>
              <Link to="/notes/privacy-policy" className="text-black font-semibold opacity-90 block">
                Privacy Policy
              </Link>
              <Link to="/notes/cookie-policy" className="text-black font-semibold opacity-90 block">
                Cookie Policy
              </Link>
            </div>
            <div className="px-3.5 md:px-4 py-3.5 md:py-4 col-span-2 md:col-span-1 lg:col-span-2 text-center">
              <img src="../assets/img/logo/brand-full.png" width="200" className="mx-auto" onClick={() => {setCount(count+1)}} />
              <div className="h-5"></div>
              <div className="font-semibold text-[.85rem]">
                © Copyright {appData.applicationInfo.appName} 2022. All rights reserved. 
                <div className="text-[.7rem] mt-1">v{appData.applicationInfo.appVersion}</div>
                {count != 0 ? <div className="mt-2"></div> : ""} {count == 0 ? "" : count == 25 ? "You are a ROBUX Master!" : count == 50 ? <button className="button success" onClick={() => {window.location="https://youtube.com/watch?v=dQw4w9WgXcQ"}}>Claim 100 Robux</button> : count}
              </div>
            </div>
          </div>
        </main>
      </footer>
    );
  };
  
  export default Footer;
  