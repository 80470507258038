import { Link } from "react-router-dom";
import HelpNav from "../../navigation/HelpNav";

const WithdrawingRobux = () => {
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
        <HelpNav />
        <div className="md:col-span-4 card">
          <div className="body">
            <h1 className="text-2xl font-bold">How do I withdraw ROBUX?</h1>
            <p className="font-medium text-sm">
              On the{" "}
              <Link to="/withdraw" className="underline text-blue-400">
                withdrawal
              </Link>{" "}
              page, choose a method and follow the steps:
            </p>
            <h1 className="text-xl font-semibold my-3">Groups</h1>
            <ol>
              <li>1. Select or enter the amount of ROBUX</li>
              <li>2. Click "Group Funds"</li>
              <li>3. Join the group listed on the prompt</li>
              <li>4. Check the box verifying you have joined the group</li>
              <li>5. Click "Withdraw" and you're all done!</li>
            </ol>
            <h1 className="text-xl font-semibold mt-3">Gamepasses</h1>
            <p className="font-medium italic text-sm mb-3">
              Video tutorial:{" "}
              <a
                href="https://youtu.be/dQw4w9WgXcQ"
                target="_blank"
                className="underline text-blue-400"
              >
                https://youtu.be/dQw4w9WgXcQ
              </a>
            </p>
            <ol>
              <li>1. Select or enter the amount of ROBUX</li>
              <li>
                2. Create a gamepass{" "}
                <a
                  href="https://www.roblox.com/develop?Page=game-passes"
                  target="_blank"
                  className="underline text-blue-400"
                >
                  here
                </a>{" "}
                on your linked account
              </li>
              <li>
                2. Set the price to the one listed on the prompt and put it
                on-sale
              </li>
              <li>
                3. Click "Next" and paste the URL to your gamepass in the
                textbox{" "}
                <span className="italic text-sm">
                  (Example:{" "}
                  <a
                    href="https://www.roblox.com/game-pass/86893/Manager-Teleporter"
                    target="_blank"
                    className="underline text-blue-400"
                  >
                    https://www.roblox.com/game-pass/86893/Manager-Teleporter
                  </a>
                  )
                </span>
              </li>
              <li>
                4. Verify the information is correct, click "Next", and then
                click "Purchase" and you're all done!
              </li>
            </ol>
            <h1 className="text-2xl font-bold my-3">When do you restock?</h1>
            <p>
              We try to keep our website constantly stocked. When there is
              delays we usually stock our website within 24 hours from time of
              the last payout. If we are out of stock, please don't contact us
              for support, as we will be aware of the problem and working to fix
              it as soon as possible.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default WithdrawingRobux;
