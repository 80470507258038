import { Link } from "react-router-dom";
import NotesNav from "../../navigation/NotesNav";

const Cookie = () => {
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
      <NotesNav />
        <div className="md:col-span-4 card">
          <div className="body">
            <h1 className="text-4xl mb-3">Cookie Policy</h1>
            <h1 className="text-2xl font-bold my-3">1. About cookies</h1>
            <p className="my-[1rem]">
              1.1 A cookie is a file containing an identifier (a string of
              letters and numbers) that is sent by a web server to a web browser
              and is stored by the browser. The identifier is then sent back to
              the server each time the browser requests a page from the server.
            </p>
            <p className="my-[1rem]">
              1.2 Cookies may be either "persistent" cookies or "session"
              cookies: a persistent cookie will be stored by a web browser and
              will remain valid until its set expiry date, unless deleted by the
              user before the expiry date; a session cookie, on the other hand,
              will expire at the end of the user session, when the web browser
              is closed.
            </p>
            <p className="my-[1rem]">
              1.3 Cookies do not typically contain any information that
              personally identifies a user, but personal information that we
              store about you may be linked to the information stored in and
              obtained from cookies.
            </p>

            <h1 className="text-2xl font-bold my-3">2. Cookies that we use</h1>
            <p className="my-[1rem]">
              2.1 We use cookies for the following purposes:
            </p>
            <ol className="list-decimal list-inside my-[1.5rem] ml-[3rem]">
              <li className="mb-[1rem]">
                authentication - we use cookies to identify you when you visit
                our website and as you navigate our website (purposes for these
                cookies are account verification, account authentication,
                website functionality);
              </li>
              <li className="mb-[1rem]">
                advertising - we use cookies to help us to display
                advertisements that will be relevant to you (cookies used for
                this purpose are: Google AdSense, Google AdExchange, Avocet,
                PulsePoint, Teads, Skimlinks, Monetizer 101);
              </li>
              <li className="mb-[1rem]">
                analysis - we use cookies to help us to analyse the use and
                performance of our website and services (cookies used for this
                purpose are: Google Analytics, ComScore, Google Tag Manager,
                Crazy Egg, Facebook Audience, Google Analytics Audience);
              </li>
              <li className="mb-[1rem]">
                cookie consent - we use cookies to store your preferences in
                relation to the use of cookies more generally.
              </li>
            </ol>

            <h1 className="text-2xl font-bold my-3">
              3. Cookies used by our service providers
            </h1>
            <p className="my-[1rem]">
              3.1 Our service providers use cookies and those cookies may be
              stored on your computer when you visit our website.
            </p>
            <p className="my-[1rem]">
              3.2 We use Google Analytics to analyze the use of our website.
              Google Analytics gathers information about website use by means of
              cookies. The information gathered relating to our website is used
              to create reports about the use of our website. Google's privacy
              policy is available at:{" "}
              <a
                href="https://policies.google.com/privacy"
                className="underline text-blue-400"
                target="_blank"
              >
                https://policies.google.com/privacy
              </a>
              .
            </p>

            <h1 className="text-2xl font-bold my-3">4. Managing cookies</h1>
            <p className="my-[1rem]">
              4.1 Most browsers allow you to refuse to accept cookies and to
              delete cookies. The methods for doing so vary from browser to
              browser, and from version to version. You can however obtain
              up-to-date information about blocking and deleting cookies via
              these links:
            </p>
            <ol className="list-decimal list-inside my-[1.5rem] ml-[3rem]">
              <li className="mb-[1rem]">
                <a
                  href="https://support.google.com/chrome/answer/95647?hl=en"
                  className="underline text-blue-400"
                  target="_blank"
                >
                  Chrome
                </a>
                ;
              </li>
              <li className="mb-[1rem]">
                <a
                  href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                  className="underline text-blue-400"
                  target="_blank"
                >
                  Firefox
                </a>
                ;
              </li>
              <li className="mb-[1rem]">
                <a
                  href="https://blogs.opera.com/news/2015/08/how-to-manage-cookies-in-opera"
                  className="underline text-blue-400"
                  target="_blank"
                >
                  Opera
                </a>
                ;
              </li>
              <li className="mb-[1rem]">
                <a
                  href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"
                  className="underline text-blue-400"
                  target="_blank"
                >
                  Internet Explorer
                </a>
                ;
              </li>
              <li className="mb-[1rem]">
                <a
                  href="https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
                  className="underline text-blue-400"
                  target="_blank"
                >
                  Safari
                </a>
                ; and
              </li>
              <li className="mb-[1rem]">
                <a
                  href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy"
                  className="underline text-blue-400"
                  target="_blank"
                >
                  Edge
                </a>
                ;
              </li>
            </ol>
            <p className="my-[1rem]">
              4.2 Blocking all cookies will have a negative impact upon the
              usability of many websites.
            </p>
            <p className="my-[1rem]">
              4.3 If you block cookies, you will not be able to use all the
              features on our website.
            </p>

            <h1 className="text-2xl font-bold my-3">
              5. Advertisements and Analytics
            </h1>
            <p className="my-[1rem]">
              5.1 We use third party advertisements for the site to be
              functional. Some of these advertisers may be served from our third
              party advertiser. You may view the privacy policy and terms of
              service of each third-party via their respective links on our{" "}
              <Link to="/earn" className="underline text-blue-400">
                Earn page
              </Link>
              .
            </p>
            <p className="my-[1rem]">
              5.2 We use Google Analytics to analyse the use of our website.
              Google Analytics gathers information about website use by means of
              cookies. The information gathered relating to our website is used
              to create reports about the use of our website. Google's privacy
              policy is available at:{" "}
              <a
                href="https://policies.google.com/privacy"
                className="underline text-blue-400"
                target="_blank"
              >
                https://policies.google.com/privacy
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cookie;
