import { Navigate, useLocation, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useAuth } from "../../lib/AuthContext";

const Referral = ({ openLogin }) => {
  const { id } = useParams();
  let location = useLocation();
  const { authState } = useAuth();

  useEffect(() => {
    if (authState === false) {
      openLogin();
      Cookies.set("kyloReferral", id, { expires: 3 });
    }
  }, []);
  return <Navigate to="/" state={{ from: location }} />;
};

export default Referral;
