import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useApp } from "../../../lib/AppContext";
import NotesNav from "../../navigation/NotesNav";

const Terms = () => {
    const {appData} = useApp();
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
        <NotesNav />
        <div className="md:col-span-4 card">
          <div className="body">
            <h1 className="text-4xl mb-3">Terms of Service</h1>
            <p>
              By accessing the website at {appData.applicationInfo.appName}, you are agreeing to be
              bound by these terms of service, all applicable laws and
              regulations, and agree that you are responsible for compliance
              with any applicable local laws. If you do not agree with any of
              these terms, you are prohibited from using or accessing this site.
              The materials contained in this website are protected by
              applicable copyright and trademark law.
            </p>
            <h1 className="text-2xl font-bold my-3">Use License</h1>
            <ol className="list-[upper-roman] list-inside">
              <li>
                Permission is granted to temporarily download one copy of the
                materials (information or software) on {appData.applicationInfo.appName} website for
                personal, non-commercial transitory viewing only. This is the
                grant of a license, not a transfer of title, and under this
                license you may not:
                <ol className="list-[lower-alpha] list-inside my-[1.5rem] ml-[3rem]">
                  <li className="mb-[1rem]">modify or copy the materials;</li>
                  <li className="mb-[1rem]">
                    use the materials for any commercial purpose, or for any
                    public display (commercial or non-commercial);
                  </li>
                  <li className="mb-[1rem]">
                    attempt to decompile or reverse engineer any software
                    contained on {appData.applicationInfo.appName} website;
                  </li>
                  <li className="mb-[1rem]">
                    remove any copyright or other proprietary notations from the
                    materials; or
                  </li>
                  <li className="mb-[1rem]">
                    transfer the materials to another person or "mirror" the
                    materials on any other server.
                  </li>
                </ol>
              </li>
              <li>
                This license shall automatically terminate if you violate any of
                these restrictions and may be terminated by {appData.applicationInfo.appName} at any
                time. Upon terminating your viewing of these materials or upon
                the termination of this license, you must destroy any downloaded
                materials in your possession whether in electronic or printed
                format.
              </li>
            </ol>
            <h1 className="text-2xl font-bold my-3">Disclaimer</h1>
            <ol className="list-[upper-roman] list-inside">
              <li className="mb-[1rem]">
                The materials on {appData.applicationInfo.appName} website are provided on an 'as is'
                basis. {appData.applicationInfo.appName} makes no warranties, expressed or implied,
                and hereby disclaims and negates all other warranties including,
                without limitation, implied warranties or conditions of
                merchantability, fitness for a particular purpose, or
                non-infringement of intellectual property or other violation of
                rights.
              </li>
              <li>
                Further, {appData.applicationInfo.appName} does not warrant or make any
                representations concerning the accuracy, likely results, or
                reliability of the use of the materials on its website or
                otherwise relating to such materials or on any sites linked to
                this site.
              </li>
            </ol>
            <h1 className="text-2xl font-bold my-3">Limitations</h1>
            <p>
              In no event shall {appData.applicationInfo.appName} or its suppliers be liable for any
              damages (including, without limitation, damages for loss of data
              or profit, or due to business interruption) arising out of the use
              or inability to use the materials on {appData.applicationInfo.appName} website, even if
              {appData.applicationInfo.appName} or a {appData.applicationInfo.appName} authorized representative has been
              notified orally or in writing of the possibility of such damage.
              Because some jurisdictions do not allow limitations on implied
              warranties, or limitations of liability for consequential or
              incidental damages, these limitations may not apply to you.
            </p>
            <h1 className="text-2xl font-bold my-3">Accuracy of materials</h1>
            <p>
              The materials appearing on {appData.applicationInfo.appName} website could include
              technical, typographical, or photographic errors. {appData.applicationInfo.appName} does
              not warrant that any of the materials on its website are accurate,
              complete or current. {appData.applicationInfo.appName} may make changes to the materials
              contained on its website at any time without notice. However
              {appData.applicationInfo.appName} does not make any commitment to update the materials.
            </p>
            <h1 className="text-2xl font-bold my-3">Links</h1>
            <p>
              {appData.applicationInfo.appName} has not reviewed all of the sites linked to its
              website and is not responsible for the contents of any such linked
              site. The inclusion of any link does not imply endorsement by
              {appData.applicationInfo.appName} of the site. Use of any such linked website is at the
              user's own risk.
            </p>
            <h1 className="text-2xl font-bold my-3">Modifications</h1>
            <p>
              {appData.applicationInfo.appName} may revise these terms of service for its website at
              any time without notice. By using this website you are agreeing to
              be bound by the then current version of these terms of service.
            </p>
            <h1 className="text-2xl font-bold my-3">Governing Law</h1>
            <p>
              These terms and conditions are governed by and construed in
              accordance with the laws of California and you irrevocably submit
              to the exclusive jurisdiction of the courts in that State or
              location.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
