import React from "react";

class Tab extends React.Component {
    render() {
        if (this.props.isSelected) {
            return (
                <span className="tab-content">
                    { this.props.children }
                </span>
            );
        }

        return null;
    }
}

export default Tab;