import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";

function RequireNoAuth() {
  // onOpen opens the login modal
  let { authState } = useAuth();
  let location = useLocation();
  let navigate = useNavigate();

  if (authState) {
    return <Navigate to="/earn" state={{ from: location }} />;
  }

  return <Outlet />;
}

export default RequireNoAuth;
