import { createContext, useContext } from "react";
import { QueryClient, QueryClientProvider as QProvider } from "react-query";

const QueryContext = createContext({});

const queryClient = new QueryClient();

export const QueryClientProvider = props => {

  const queryContextValue = {
    queryClient,
};
return <QProvider client={queryClient}><QueryContext.Provider value={queryContextValue} {...props} /></QProvider>;
}

export const useQueryClient = () => useContext(QueryContext);